import React, { Component } from 'react';
import { Button, Table, Header, Grid, Icon, Modal, Popup, Dropdown, Input } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import { connect } from 'react-redux';
import { corteActions } from '../corte.actions';
import DatePicker from 'react-date-picker';
import { CustomLoader } from '../../_custom';


class ListaAbonoFactura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            idcorte: 0,
            fechaPoliza: new Date(),
            tipodeposito: "",
            cortes: [],
            fechacorte: "",
            companies: [],
            total: "",
            importe: "",
            banco: "",
            company: '',
            sucursal: '',
            referencia: '',
            totalSearch : ''
        }
    }
    show = (id, tipodeposito, total, importe, banco, sucursal) => () => this.setState({
        open: true,
        idcorte: id,
        tipodeposito: tipodeposito,
        total: total,
        importe: importe,
        banco: banco,
        sucursal: sucursal
    })



    close = () => this.setState({
        open: false
    })

    componentDidMount() {
        const { cortes } = this.props
        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
        this.props.getabonofactura(idUser);
        this.getEmpresas();
        this.setState({
            cortes: cortes
        });
    }

    getEmpresas() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.companies) {
            let array = [];
            for (let index = 0; index < user.companies.length; index++) {
                let company = {
                    'key': user.companies[index].id,
                    'text': user.companies[index].nombre,
                    'value': user.companies[index].nombre
                }
                array.push(company);
            }
            this.setState({
                companies: array
            });
        }
    }
    onSelectionChanged = (e, data) => {
        const { fechacorte, referencia } = this.state;
        let fechaform = ''
        if (fechacorte !== '') {
            let date = new Date(fechacorte)
            let mes = (date.getMonth()) + 1;
            let dia = date.getDate()
            fechaform = date.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
        }
        let filtro =
        {
            fechacorte: fechaform,
            referencia: referencia
        }
        this.setState({ company: data.value });
        if (data.value !== '' || fechaform !== '' || referencia !=='') {
            this.props.filteringcorte(filtro)
        }
        else {
            this.props.filteringcorte()
        }
    }

    onDateChangedcorte = date => {
        const { referencia,totalSearch } = this.state
        let fechaform = ''
        let filtro =
        {
            fechacorte: fechaform,
            total: totalSearch,
            referencia: referencia
        }
        if (date !== null) {
            let mes = (date.getMonth()) + 1;
            let dia = date.getDate()
            fechaform = date.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
            this.setState({
                fechacorte: date
            })
            filtro =
            {
                fechacorte: fechaform,
                total: totalSearch,
                referencia: referencia
            }
            this.props.filteringcorte(filtro)
        }
        else {
            if (totalSearch !== '' || referencia !== '') {
                this.props.filteringcorte(filtro)
            }
            else {
                this.props.filteringcorte()
            }
        }
    }

    onDateChangedpoliza = date => {
        this.setState({
            fechaPoliza: date
        })
    }

    handleInputChangeReferencia = (e) => {
        const { fechacorte, totalSearch } = this.state;
        let fechaform = ''
        if (fechacorte !== '') {
            let date = new Date(fechacorte)
            let mes = (date.getMonth()) + 1;
            let dia = date.getDate()
            fechaform = date.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
        }
        let amount = e.target.value;
        this.setState({ referencia: amount })
        let filtro =
        {
            fechacorte: fechaform,
            total: totalSearch,
            referencia: e.target.value
        }
        if ( totalSearch !== '' || fechaform !== '' || amount !== '') {
            this.props.filteringcorte(filtro)
        }
        else {
            this.props.filteringcorte()
        }
    }

    handleInputChangetotal = (e) => {
        const { fechacorte, referencia } = this.state;
        let fechaform = ''
        if (fechacorte !== '') {
            let date = new Date(fechacorte)
            let mes = (date.getMonth()) + 1;
            let dia = date.getDate()
            fechaform = date.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
        }
        let amount = e.target.value;
        this.setState({ totalSearch: amount })
        let filtro =
        {
            fechacorte: fechaform,
            total: e.target.value,
            referencia: referencia
        }
        if (fechaform !== '' || amount !== ''  || referencia !== '') {
            this.props.filteringcorte(filtro)
        }
        else {
            this.props.filteringcorte()
        }
    }

    clearstate() {
        this.setState({
            open: false,
            idcorte: 0,
            fechaPoliza: new Date(),
            idempresa: 0,
            sucursal: '',
            company: '',
            fechacorte: "",
        });
    }

    onSubmit = () => {
        const { idcorte, tipodeposito, fechaPoliza, total, importe, banco, sucursal } = this.state
        let metodo = tipodeposito == 1 ? 'efectivo' : tipodeposito == 2 ? 'tarjeta' : tipodeposito == 3 ? 'Amex' : ''
        let mes = (fechaPoliza.getMonth()) + 1;
        let dia = fechaPoliza.getDate()
        let fechaform = fechaPoliza.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
        let nombre = 'Poliza ' + metodo + ' ' + sucursal + ' ' + fechaform;
        let extension = '.xls';
        let datospoliza = [
            {
                tipo_pago: tipodeposito,
                total: total,
                importe: importe,
                banco: banco,
                concepto: 'DEPOSITO DEL DIA'
            }
        ]
        let data = {
            idrelacionado: idcorte,
            fechapoliza: fechaPoliza,
            datospoliza,
            nombre: nombre,
            extension, extension
        }
        this.clearstate();
        this.props.crearPoliza(data);

    }

    render() {
        const { requesting, creating, cortes, polizasCredas } = this.props;
        const { open, fechaPoliza, fechacorte, companies, referencia,totalSearch } = this.state;
        let tipo_user = JSON.parse(localStorage.getItem("user")).user.id_tipo;
        if (requesting) {
            return <CustomLoader active inline='centered'
                text='Cargando cortes' />
        }
        else if (creating) {
            return <CustomLoader active inline='centered'
                text='Creando poliza' />
        }
        return (<Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='medium' dividing>
                        <Icon name='money bill alternate' />
                        <Header.Content>
                            {`Cortes`}
                            <Header.Subheader>{}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
                <Grid.Column>
                    <DatePicker
                        value={fechacorte}
                        onChange={this.onDateChangedcorte}
                        locale={'es-419'}
                        format={'yyyy-MM-dd'}
                    />
                </Grid.Column>
               <Grid.Column>
                    <Input icon='search' type='text'
                        placeholder='Total...'
                        onKeyDown={this.keyHandler}
                        onChange={(e) => this.handleInputChangetotal(e)}
                        value={totalSearch}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
                <Grid.Column>
                    <Input icon='search' type='text'
                        placeholder='Referencia...'
                        onChange={(e) => this.handleInputChangeReferencia(e)}
                        onKeyDown={this.keyHandler}
                        value={referencia}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row colums={2}>
                <Grid.Column width={11}>
                    <Table color='teal' selectable  >
                        <Table.Header fullWidth>
                            <Table.Row>
                            <Table.HeaderCell>empresa</Table.HeaderCell>
                                <Table.HeaderCell>Fecha</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                                <Table.HeaderCell>Tipo pago</Table.HeaderCell>
                                <Table.HeaderCell>Facturas</Table.HeaderCell>
                                <Table.HeaderCell>Referencias</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                cortes.map((c, i) => {
                                    const totalCorte = c.total_corte;
                                    const disablebutton = polizasCredas.filter(boton => boton.tipo == 1 && boton.id_corte == c.id).length > 0 ? true : false;
                                    const contentbutton = disablebutton ? "Poliza de creada" : "Poliza";
                                    if (tipo_user == 1) {
                                        return (<Table.Row key={c.id} >
                                            <Table.Cell >{c.empresa}</Table.Cell>
                                            <Table.Cell >{c.fecha_creacion}</Table.Cell>
                                            <Table.Cell><Currency quantity={totalCorte} /></Table.Cell>
                                            <Table.Cell>{c.nombre}</Table.Cell>
                                            <Table.Cell>{c.facturas}</Table.Cell>
                                            <Table.Cell>{c.referencias}</Table.Cell>
                                            <Table.Cell>
                                                <Popup content={contentbutton} inverted trigger={
                                                    <Button onClick={this.show(c.id, 1, totalCorte, totalCorte, 1, c.empresa)} disabled={totalCorte == 0 ? true : false} basic={!disablebutton} color='black' icon color='black'>
                                                        <Icon name='file excel outline' />
                                                    </Button>} />
                                            </Table.Cell>
                                        </Table.Row>
                                        )
                                    }
                                }
                                )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
            <Modal size='mini' open={open}>
                <Modal.Header >
                    Selecciona la Fecha con la que quieres crear la poliza
                </Modal.Header>
                <Modal.Actions>
                    <DatePicker
                        value={fechaPoliza}
                        onChange={this.onDateChangedpoliza}
                        clearIcon={null}
                        locale={'es-419'}
                        format={'yyyy-MM-dd'}
                    />
                    <Button size='mini' negative onClick={this.close}>Cancelar</Button>
                    <Button size='mini' positive onClick={this.onSubmit}>Confirmar</Button>
                </Modal.Actions>
            </Modal>
        </Grid>)
    }
}
const mapStateToProps = (state) => {
    return {
        cortes: state.corte.cortes ? state.corte.cortes : [],
        polizasCredas: state.corte.polizasCreadas ? state.corte.polizasCreadas : [],
        requesting: state.corte.requesting,
        creating: state.corte.creating,
        created: state.corte.created
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getabonofactura: (idUser) => dispatch(corteActions.getAbonoFacturaAll(idUser)),
        crearPoliza: (data) => dispatch(corteActions.crearPolizaFactura(data)),
        filteringcorte: (filtro) => dispatch(corteActions.filteringAbono(filtro))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaAbonoFactura)