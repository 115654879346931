import React from 'react'
import { connect } from 'react-redux';
import { Header, Divider, Grid, Button, Modal, GridRow,Label} from 'semantic-ui-react';
//import Currency from 'react-currency-formatter';
import XLSX from 'xlsx';

const square = { width: '100%' }


const AbonoResponse = () => {

    return (
        <Grid centered padded style={square}>
          <Grid.Column>
            <Header as='h2' icon textAlign='center'>
              <GridRow>
                <Label> creado correctamente</Label>
              </GridRow>
                <Divider horizontal> </Divider>
                {/*
                <Button size='big' positive onClick={()=>handleClick()}>Descargar</Button>*/ }
                <Button size='big' href="./abono-factura">Regresar</Button>
            </Header>
          </Grid.Column>
        </Grid>
    );
}

export default (AbonoResponse)