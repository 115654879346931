import { alertActions } from '../../Alert'
import { chargeCodeConstants, chargeCodeService } from '.'
import { exportarCWActions } from '../'

export const chargeCodeActions = {
    actualizarChargeCode,
    listarConceptosPendientes
}

function actualizarChargeCode(listaConceptos){
    return dispatch => {
      dispatch(request())
      if(listaConceptos){
        console.log(listaConceptos)
        chargeCodeService.actualizarChargeCode(listaConceptos)
          .then(
            response => {
              dispatch(success(response.data))
              dispatch(alertActions.success(response.message))
              
              dispatch(exportarCWActions.getAgentes())
              dispatch(listarConceptosPendientes())
            },
            error => {
              dispatch(failure(error.toString()))
              dispatch(alertActions.error(error.toString()))
            }
          )
      }
    }
    function request(){ return { type: chargeCodeConstants.CODE_LOAD_REQUEST } }
    function success(response){ return { type: chargeCodeConstants.CODE_LOAD_SUCCESS, response } }
    function failure(){ return { type: chargeCodeConstants.CODE_LOAD_FAILURE } }
}



function listarConceptosPendientes(){
  return dispatch => {
    dispatch(request())
    chargeCodeService.listarConPen()
      .then(
        response => {
          dispatch(success(response.data))
          //dispatch(alertActions.success(response.message))
        },
        error => {
          dispatch(failure(error.toString()))
          //dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(){return {type: chargeCodeConstants.GET_CONCEPTOS_REQUEST}}
  function success(response){return {type: chargeCodeConstants.GET_CONCEPTOS_SUCCESS, response}}
  function failure(){return {type: chargeCodeConstants.GET_CONCEPTOS_FAILURE}}
}
