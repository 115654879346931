
import { AgentesMainScreen } from '../../modules/Agente'
import { CuentasMainScreen } from '../../modules/Cuenta';
import { FacturaMainScreen, FacturasList, CompResponse, ImportarFacturaXML } from '../../modules/Factura';
import { ListaFacturas, HistorialFacturas } from '../../modules/Listas'
import { ImportarListaNegra } from '../../modules/ListaNegra'
import { ExportarCW, ImportarCW, CargarChargeCode } from '../../modules/CargoWise'
import { CorteCaja, ListarCorte, AbonoFactura, ListarAbonoFactura } from '../../modules/CorteCaja'
import { ReporteBanco } from '../../modules/Reportes'
import { ConceptosPendientes } from '../../modules/CargoWise'

import { ImportarXML } from '../../modules/CargarFacturas';
import { ImportarComplemento } from '../../modules/CargarComplementos';
import { ImportarOpinion } from '../../modules/Opinion'
import Inicio from '../../modules/Inicio/Inicio';

export const components = {
    ConceptosPendientes: ConceptosPendientes,
    AgentesMainScreen: AgentesMainScreen,
    CuentasMainScreen: CuentasMainScreen,
    FacturaMainScreen: FacturaMainScreen,
    FacturasList: FacturasList,
    CompResponse: CompResponse,
    ListaFacturas: ListaFacturas,
    HistorialFacturas: HistorialFacturas,
    ImportarListaNegra: ImportarListaNegra,
    ExportarCW: ExportarCW,
    ImportarCW: ImportarCW,
    CargarChargeCode: CargarChargeCode,
    ImportarXML: ImportarXML,
    ImportarComplemento: ImportarComplemento,
    ImportarOpinion: ImportarOpinion,
    Inicio: Inicio,
    CorteCaja: CorteCaja,
    ImportarFacturaXML: ImportarFacturaXML,
    ListarCorte: ListarCorte,
    ListarAbonoFactura: ListarAbonoFactura,
    ReporteBanco: ReporteBanco,
    AbonoFactura: AbonoFactura
}

export * from './ModuleLink'
