import {
    getFacturasCorte,
    CreateCorteCaja,
    getListarCortes,
    createPoliza,
    getAbonoFactura
} from '.';

import { uriDevelop, headers } from '../../service'

export const corteService = {
    getAll,
    crearCorteCaja,
    getAllCortes,
    crearPoliza,
    getAllAbonoFactura
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getAll(idEmpresa, fechasfactura) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    let apirequest = `${uriDevelop}${getFacturasCorte}?idEmpresa=${idEmpresa}`;
    if (fechasfactura != null)
        apirequest += `&fecha1=${fechasfactura[0]}&fecha2=${fechasfactura[1]}`;

    return fetch(apirequest, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getAllCortes(idUsuario, fechascorte, company) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    let apirequest = `${uriDevelop}${getListarCortes}?idUsuario=${idUsuario}`;
    if (fechascorte != null)
        apirequest += `&fecha1=${fechascorte[0]}&fecha2=${fechascorte[1]}`;
    if (company != null)
        apirequest += `&company=${company}`;
    return fetch(apirequest, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getAllAbonoFactura(idUsuario) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    return fetch(`${uriDevelop}${getAbonoFactura}?idUsuario=${idUsuario}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function crearCorteCaja(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
    };

    return fetch(`${uriDevelop}${CreateCorteCaja}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

/*function crearAbonoFactura(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
    };

    return fetch(`${uriDevelop}${CreateAbono}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}*/

function crearPoliza(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
    };

    return fetch(`${uriDevelop}${createPoliza}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}