import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="ui inverted footer segment">
                Footer
            </div>
        );
    }
}

export default Footer;