import React, { Component } from 'react';
import { Icon, Table, Header, Grid, Popup, Button, Input, GridRow } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Currency from 'react-currency-formatter';
import DatePicker from 'react-date-picker';
import { facturaActions } from '..';
import { CustomLoader } from '../../_custom';
import ComplementoPago from './ComplementoPago';


class FacturasList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folio: "",
            serie: '',
            totalSearch: '',
        }
    }

    onClickfilter() {
        const { folio, serie, totalSearch } = this.state;
        this.props.getFacturas(folio, serie, totalSearch);
    }

    onClickDel(id) {
        this.props.delFacturas(id);
    }

    handleInputChangefolio = (e) => {
        let amount = e.target.value;
        if (amount !== '') {
            this.setState({ folio: amount })
        }
        else {
            this.setState({ folio: '' })
        }
    }
    handleInputChangetotal = (e) => {
        let amount = e.target.value;
        if (amount !== '') {
            this.setState({ totalSearch: amount })
        }
        else {
            this.setState({ totalSearch: '' })
        }
    }

    handleInputChangeserie = (e) => {
        let amount = e.target.value;
        if (amount !== '') {
            this.setState({ serie: amount })
        }
        else {
            this.setState({ serie: '' })
        }
    }

    //Falta redireccionar a not found cuando no se encuentre el id agente
    render() {
        const { facturas, requesting } = this.props;
        const { serie, folio, totalSearch } = this.state;
        if (requesting) {
            return <CustomLoader active inline />;
        }
        else {
            return (
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header size='medium' dividing>
                                <Header.Content>
                                    {`Listas de Facturas`}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Input icon='search' type='text'
                                placeholder='Total...'
                                onKeyDown={this.keyHandler}
                                onChange={(e) => this.handleInputChangetotal(e)}
                                value={totalSearch}
                                pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                        </Grid.Column>
                        <Grid.Column>
                            <Input icon='search' type='text'
                                placeholder='serie...'
                                onChange={(e) => this.handleInputChangeserie(e)}
                                onKeyDown={this.keyHandler}
                                value={serie}
                                pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                        </Grid.Column>
                        <Grid.Column>
                            <Input icon='search' type='text'
                                placeholder='folio...'
                                onChange={(e) => this.handleInputChangefolio(e)}
                                onKeyDown={this.keyHandler}
                                value={folio}
                                pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                        </Grid.Column>
                        <Grid.Column>
                            <GridRow>
                                &nbsp;
                            </GridRow>
                            <GridRow>
                                <Popup content={"buscar"} inverted trigger={
                                    <Button color='green' icon color='green' onClick={() => this.onClickfilter()}>
                                        Buscar
                                    </Button>} />
                            </GridRow>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Table selectable>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>Fecha Factura</Table.HeaderCell>
                                        <Table.HeaderCell>Serie-Folio</Table.HeaderCell>
                                        <Table.HeaderCell>RFC</Table.HeaderCell>
                                        <Table.HeaderCell>Moneda</Table.HeaderCell>
                                        <Table.HeaderCell>Total Factura</Table.HeaderCell>
                                        <Table.HeaderCell>Acciones</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {facturas ? facturas.map(f =>
                                    (
                                        <Table.Row key={f.key} {...(f.acumulado === f.total ? { positive: true } : {})}>
                                            <Table.Cell>{f.fecha.split(" ")[0]}</Table.Cell>
                                            <Table.Cell>{`${f.serie}-${f.folio}`}</Table.Cell>
                                            <Table.Cell>{f.rfc}</Table.Cell>
                                            <Table.Cell>{f.moneda}</Table.Cell>
                                            <Table.Cell>
                                                <Currency quantity={f.total} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Popup content={"eliminar factura"} inverted trigger={
                                                    <Button color='red' icon color='red' onClick={() => this.onClickDel(f.id)}>
                                                        <Icon name='cancel' />
                                                    </Button>} />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                    ) : null}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

    }

}

const mapStateToProps = (state) => {
    return {
        facturas: state.factura.facturas ? state.factura.facturas : [],
        requesting: state.factura.requesting,
        response: state.response
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFacturas: (folio, serie, totalSearch) => dispatch(facturaActions.getAll(folio, serie, totalSearch)),
        delFacturas: (id) => dispatch(facturaActions.delFactura(id))
        /* _delete : (id) => dispatch(tiposErogacionActions._delete(id))  */
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacturasList)
