

export const corteConstants = {
  GETFACTURA_REQUEST: 'GetFacturaCorte_Request',
  GETFACTURA_SUCCESS: 'GetFacturaCorte_Success',
  GETFACTURA_FAILURE: 'GetFacturaCorte_Failure',

  CREATECORTE_REQUEST: 'CreateCorte_Request',
  CREATECORTE_SUCCESS: 'CreateCorte_Success',
  CREATECORTE_FAILURE: 'CreateCorte_Failure',

  /*CREATEABONO_REQUEST: 'CreateAbono_Request',
  CREATEABONO_SUCCESS: 'CreateAbono_Success',
  CREATEABONO_FAILURE: 'CreateAbono_Failure',*/

  GETLISTCORTE_REQUEST: 'Getlistcorte_Request',
  GETLISTCORTE_SUCCESS: 'Getlistcorte_Success',
  GETLISTCORTE_FAILURE: 'Getlistcorte_Failure',

  GETLISTABONO_REQUEST: 'Getlistabono_Request',
  GETLISTABONO_SUCCESS: 'Getlistabono_Success',
  GETLISTABONO_FAILURE: 'Getlistabono_Failure',

  CREATEPOLIZA_REQUEST: 'CreatePoliza_Request',
  CREATEPOLIZA_SUCCESS: 'CreatePoliza_Success',
  CREATEPOLIZA_FAILURE: 'CreatePoliza_Failure',

  CREATEPOLIZAFACTURA_REQUEST: 'CreatePolizaFactura_Request',
  CREATEPOLIZAFACTURA_SUCCESS: 'CreatePolizaFactura_Success',
  CREATEPOLIZAFACTURA_FAILURE: 'CreatePolizaFactura_Failure',


  FILTER_FACTURASCORTES: 'FilterFacturaCorte',
  FILTER_FACTURASABONO: 'FilterFacturasAbono',
  FILTER_FACTURAS_CLEAR: 'FilterFacturasClear',
  FILTER_CORTES_CLEAR: 'FilterCortesClear',
  FILTER_CORTES: 'Filtercortes',
  FILTER_ABONO: 'Filterabono',

};

export const formatDate = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZone: 'America/Los_Angeles'
};

export const tipoCorte = [
  {
    key: '1',
    text: 'Corte Caja',
    value: 'Corte',
  },
  {

    key: '2',
    text: 'Cobranza',
    value: 'Cobranza',
  }
];

export const tipoCuenta = [
  {
    key: '1',
    text: 'Cuenta Ref',
    value: '1',
  },
  {

    key: '2',
    text: 'Cuenta Eje',
    value: '2',
  }
];

export const tipoPoliza = [
  {
    key: '1',
    text: 'Efectivo',
    value: '1',
  },
  {
    key: '2',
    text: 'Tarjeta',
    value: '2',
  },
  {
    key: '3',
    text: 'AMEX',
    value: '3',
  }
  ,
  {
    key: '4',
    text: 'Cheque',
    value: '4',
  }
];

export const getFacturasCorte = '/corte/listar'
export const CreateCorteCaja = '/corte/crear-corte'
//export const CreateAbono = '/corte/crear-abono'
export const getListarCortes = '/corte/listar-corte'
export const getAbonoFactura = '/corte/listar-abono-factura'
export const createPoliza = '/corte/crear-poliza'