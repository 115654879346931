import React from 'react'
import { Table, Icon, Popup, Input } from 'semantic-ui-react'
import { CustomLoader } from '../../_custom';
import { connect } from 'react-redux'

class ValidarFacturaXML extends React.Component {
  state = {}
  constructor(props) {
    super(props)
  }


  render() {
    const { facturas, requesting } = this.props;

    const validarFactura = (factura) => {
      //console.log(factura)
      debugger
      let comprobante = factura["cfdi:Comprobante"];
      let Emisor = comprobante["cfdi:Emisor"];
      let Receptor = comprobante["cfdi:Receptor"];
      let bmybussiness = comprobante.hasOwnProperty('cfdi:Addenda');
      let result = [];
      comprobante.TipoDeComprobante === "I" && bmybussiness ? result[2] = "" : result[2] = <Popup content="Este archivo no es una factura valida" trigger={<Icon name="attention" />} />;
      //comprobante.TipoDeComprobante === "I" && bmybussiness && Emisor.RegimenFiscal && Receptor.RegimenFiscalReceptor && Receptor.UsoCFDI ? result[2] = "" : result[2] = <Popup content="Este archivo no es una factura valida" trigger={<Icon name="attention" />} />;
      return result;
    }

    const agregarFacturas = (datos) => {
      debugger
      let filas = datos.map((x, i) => {
        let comprobante = x["cfdi:Comprobante"]
        let Emisor = comprobante["cfdi:Emisor"];
        let Receptor = comprobante["cfdi:Receptor"];
        let Impuestos = comprobante["cfdi:Impuestos"];
        let status = validarFactura(x, i);
        return (
          <Table.Row className={status[0]} key={i}>
            <Table.Cell>{status[2]}{i + 1}</Table.Cell>
            <Table.Cell>{(comprobante.Serie ? comprobante.Serie + "-" : "") + comprobante.Folio}</Table.Cell>
            <Table.Cell>{comprobante.Total}</Table.Cell>
            <Table.Cell>{status[1]}{comprobante.MetodoPago}</Table.Cell>
            <Table.Cell>{status[1]}{comprobante.FormaPago}</Table.Cell>
            <Table.Cell>{status[1]}{typeof (Emisor) === 'undefined' ? 0 : Emisor.RegimenFiscal}</Table.Cell>
            <Table.Cell>{status[1]}{typeof (Receptor) === 'undefined' ? 0 : Receptor.RegimenFiscalReceptor}</Table.Cell>
            <Table.Cell>{status[1]}{typeof (Receptor) === 'undefined' ? 0 : Receptor.UsoCFDI}</Table.Cell>
            <Table.Cell>{status[1]}{typeof (Impuestos) === 'undefined' ? 0 : Impuestos.TotalImpuestosTrasladados}</Table.Cell>
            <Table.Cell>{status[1]}{typeof (Impuestos) === 'undefined' ? 0 : Impuestos.TotalImpuestosRetenidos}</Table.Cell>
          </Table.Row>
        )
      });
      return filas;
    }
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nº</Table.HeaderCell>
              <Table.HeaderCell width={2}>Serie - Folio</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Método de Pago</Table.HeaderCell>
              <Table.HeaderCell>Forma de Pago</Table.HeaderCell>
              <Table.HeaderCell>Emisor Regimen Fiscal</Table.HeaderCell>
              <Table.HeaderCell>Receptor Regimen Fiscal</Table.HeaderCell>
              <Table.HeaderCell>Uso CFDI</Table.HeaderCell>
              <Table.HeaderCell>Total Impuesto Tras</Table.HeaderCell>
              <Table.HeaderCell>Total Impuesto Ret</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {facturas ? agregarFacturas(facturas) : null}
          </Table.Body>
        </Table>
        {requesting ? <CustomLoader /> : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

const mapStateToProps = (state) => {
  return {
    requesting: state.factura.requesting,
    facturas: state.factura.strings
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidarFacturaXML);
