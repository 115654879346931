import { corteConstants, corteService } from '.';
import { alertActions } from '../Alert';
import { sheetActions } from '../ExcelSheets';
import { history } from '../../helpers'
import { empresasActions } from '../Empresas';

export const corteActions = {
    getAll,
    crearCorte,
    getCorteAll,
    crearPolizaCorte,
    crearPolizaFactura,
    filteringFacturaCorte,
    filteringFacturaAbono,
    filteringCorte,
    crearAbonoFactura,
    getAbonoFacturaAll,
    filteringAbono
};
function getAll(idEmpresa, fechasfactura = null) {
    return dispatch => {
        dispatch(request());
        let fechas = fechasfactura ? fechasfactura[0] == "" ? null : fechasfactura : null
        corteService.getAll(idEmpresa, fechas)
            .then(
                response => {
                    dispatch(success(response.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.GETFACTURA_REQUEST } }
    function success(data) { return { type: corteConstants.GETFACTURA_SUCCESS, data } }
    function failure(error) { return { type: corteConstants.GETFACTURA_FAILURE, error } }
}

function crearCorte(data) {
    return dispatch => {
        dispatch(request());
        const { listItems, sumaTotales, idCompany, montotarjeta, montoamex, montocheque, fechasCorte } = data;
        let CorteCaja = {
            id_empresa: idCompany,
            total_corte: sumaTotales,
            total_tarjeta: montotarjeta,
            total_amex: montoamex,
            total_cheque: montocheque,
            tipo_corte: 1,
            id_tipo_pago: 0,
            fechasCorte: fechasCorte
        }
        let requestBody = {
            CorteCaja,
            facturas: listItems
        }
        corteService.crearCorteCaja(requestBody)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response));
                    } else {
                        dispatch(failure(response))
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.CREATECORTE_REQUEST } }
    function success(response) { return { type: corteConstants.CREATECORTE_SUCCESS, response } }
    function failure(response) { return { type: corteConstants.CREATECORTE_FAILURE, response } }
}

function crearAbonoFactura(data) {
    return dispatch => {
        dispatch(request());
        const { listItems, sumaTotales, idCompany, metodopago, fechascorte } = data;
        let CorteCaja = {
            id_empresa: idCompany,
            total_corte: sumaTotales,
            tipo_corte: 2,
            id_tipo_pago: metodopago,
            fechasCorte: fechascorte,
        }
        let requestBody = {
            CorteCaja,
            facturas: listItems
        }
        corteService.crearCorteCaja(requestBody)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response));
                    } else {
                        dispatch(failure(response))
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.CREATECORTE_REQUEST } }
    function success(response) { return { type: corteConstants.CREATECORTE_SUCCESS, response } }
    function failure(response) { return { type: corteConstants.CREATECORTE_FAILURE, response } }
}

function crearPolizaCorte(data) {
    return dispatch => {
        dispatch(request());
        const { idrelacionado, fechapoliza, nombre, extension, datospoliza } = data;
        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
        let requestBody = {
            id_usuario: idUser,
            id_relacionado: idrelacionado,
            fecha_poliza: fechapoliza,
            datos_poliza: datospoliza
        }
        console.log(requestBody);
        corteService.crearPoliza(requestBody)
            .then(
                response => {
                    if (response.status) {
                        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
                        dispatch(success(response.data.CSV));
                        dispatch(sheetActions.exportFile(nombre, response.data.CSV, extension, () => getCorteAll(idUser)));
                    } else {
                        dispatch(failure(response))
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.CREATEPOLIZA_REQUEST } }
    function success(csv) { return { type: corteConstants.CREATEPOLIZA_SUCCESS, csv } }
    function failure(response) { return { type: corteConstants.CREATEPOLIZA_FAILURE, response } }
}

function crearPolizaFactura(data) {
    return dispatch => {
        dispatch(request());
        const { idrelacionado, fechapoliza, nombre, extension, datospoliza } = data;
        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
        let requestBody = {
            id_usuario: idUser,
            id_relacionado: idrelacionado,
            fecha_poliza: fechapoliza,
            datos_poliza: datospoliza
        }
        console.log(requestBody);
        corteService.crearPoliza(requestBody)
            .then(
                response => {
                    if (response.status) {
                        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
                        dispatch(success(response.data.CSV));
                        dispatch(sheetActions.exportFile(nombre, response.data.CSV, extension, () => getAbonoFacturaAll(idUser)));
                    } else {
                        dispatch(failure(response))
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.CREATEPOLIZAFACTURA_REQUEST } }
    function success(csv) { return { type: corteConstants.CREATEPOLIZAFACTURA_SUCCESS, csv } }
    function failure(response) { return { type: corteConstants.CREATEPOLIZAFACTURA_FAILURE, response } }
}

function getCorteAll(idUsuario, fechascorte = null, company = null) {
    return dispatch => {
        dispatch(request());

        let fechas = fechascorte ? fechascorte[0] == "" ? null : fechascorte : null
        let empresa = company ? company == "" ? null : company : null
        corteService.getAllCortes(idUsuario, fechas, empresa)
            .then(
                response => {
                    dispatch(success(response.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.GETLISTCORTE_REQUEST } }
    function success(data) { return { type: corteConstants.GETLISTCORTE_SUCCESS, data } }
    function failure(error) { return { type: corteConstants.GETLISTCORTE_FAILURE, error } }
}

function getAbonoFacturaAll(idUsuario) {
    return dispatch => {
        dispatch(request());

        corteService.getAllAbonoFactura(idUsuario)
            .then(
                response => {
                    dispatch(success(response.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: corteConstants.GETLISTABONO_REQUEST } }
    function success(data) { return { type: corteConstants.GETLISTABONO_SUCCESS, data } }
    function failure(error) { return { type: corteConstants.GETLISTABONO_FAILURE, error } }
}


function filteringFacturaCorte(filtro) {
    return dispatch => {
        if (typeof (filtro) === 'undefined')
            dispatch(filterClear());
        else
            dispatch(filter(filtro));

    };

    function filter(filtro) { return { type: corteConstants.FILTER_FACTURASCORTES, filtro } }
    function filterClear() { return { type: corteConstants.FILTER_FACTURAS_CLEAR } }
}

function filteringFacturaAbono(filtro) {
    return dispatch => {
        if (typeof (filtro) === 'undefined')
            dispatch(filterClear());
        else
            dispatch(filter(filtro));

    };

    function filter(filtro) { return { type: corteConstants.FILTER_FACTURASABONO, filtro } }
    function filterClear() { return { type: corteConstants.FILTER_FACTURAS_CLEAR } }
}

function filteringCorte(filtro) {
    return dispatch => {
        if (typeof (filtro) === 'undefined')
            dispatch(filterClear());
        else
            dispatch(filterCorte(filtro));

    };

    function filterCorte(filtro) { return { type: corteConstants.FILTER_CORTES, filtro } }
    function filterClear() { return { type: corteConstants.FILTER_CORTES_CLEAR } }
}

function filteringAbono(filtro) {
    return dispatch => {
        if (typeof (filtro) === 'undefined')
            dispatch(filterClear());
        else
            dispatch(filterAbono(filtro));

    };

    function filterAbono(filtro) { return { type: corteConstants.FILTER_ABONO, filtro } }
    function filterClear() { return { type: corteConstants.FILTER_CORTES_CLEAR } }
}



