import { reportConstants } from '../modules/Reportes';

export function reporte(state = {}, action) {
  switch (action.type) {
    case reportConstants.GETREPORTEBANCO_REQUEST:
      return {
        requesting: true,
      };
    case reportConstants.GETREPORTEBANCO_SUCCESS:
      return {
        movimientos_all: action.data.movimientos,
        movimientos: action.data.movimientos,
        requesting: false
      };
    case reportConstants.GETREPORTEBANCO_FAILURE:
      return {
        requesting: false
      };
    case reportConstants.FILTER_RMOVIMIENTOS:
      return {
        ...state,
        movimientos: state.movimientos_all.filter(movimiento => movimiento.fecha_poliza === action.filtro)
      };
    case reportConstants.FILTER_RMOVIMIENTOS_CLEAR:
      return {
        ...state,
        movimientos: state.movimientos_all
      };
    default:
      return {}
  }
}
