export const chargeCodeConstants = {
  GET_AGENTES_REQUEST: 'Get_Agentes_Request',
  GET_AGENTES_SUCCESS: 'Get_Agentes_Success',
  GET_AGENTES_FAILURE: 'Get_Agentes_Failure',

  CODE_LOAD_REQUEST: 'Code_Load_Request',
  CODE_LOAD_SUCCESS: 'Code_Load_Success',
  CODE_LOAD_FAILURE: 'Code_Load_Failure',

  GET_CONCEPTOS_REQUEST: 'Get_Conceptos_Request',
  GET_CONCEPTOS_SUCCESS: 'Get_Conceptos_Success',
  GET_CONCEPTOS_FAILURE: 'Get_Conceptos_Failure',
}

export const getAgente = '/agente/listar'
export const actualizarChargeCodeUrl = '/proveedor/actualizar-charge-code'
export const listarConPenUrl = '/proveedor/listar-con-pen'

export const chargeCodeOptions = [
  {key:'DAF-FRONT',value:'DAF-FRONT',text:'DAF-FRONT'},
  {key:'10%IVAHON',value:'10%IVAHON',text:'10%IVAHON'},
  {key:'10RETHON',value:'10RETHON',text:'10RETHON'},
  {key:'6%IVAHON',value:'6%IVAHON',text:'6%IVAHON'},
  {key:'ADCLUB',value:'ADCLUB',text:'ADCLUB'},
  {key:'ADCLUB-FRO',value:'ADCLUB-FRO',text:'ADCLUB-FRO'},
  {key:'ADINS',value:'ADINS',text:'ADINS'},
  {key:'ADINS-FRON',value:'ADINS-FRON',text:'ADINS-FRON'},
  {key:'ADMTN',value:'ADMTN',text:'ADMTN'},
  {key:'ADMTN-FRON',value:'ADMTN-FRON',text:'ADMTN-FRON'},
  {key:'ADPOST',value:'ADPOST',text:'ADPOST'},
  {key:'ADPOST-1',value:'ADPOST-1',text:'ADPOST-1'},
  {key:'ADSTNY',value:'ADSTNY',text:'ADSTNY'},
  {key:'ADSTNY-FRO',value:'ADSTNY-FRO',text:'ADSTNY-FRO'},
  {key:'ADUTIL',value:'ADUTIL',text:'ADUTIL'},
  {key:'ADUTIL-FRO',value:'ADUTIL-FRO',text:'ADUTIL-FRO'},
  {key:'AIRTRF',value:'AIRTRF',text:'AIRTRF'},
  {key:'AMS-FRONT',value:'AMS-FRONT',text:'AMS-FRONT'},
  {key:'AMSTRN',value:'AMSTRN',text:'AMSTRN'},
  {key:'BAF',value:'BAF',text:'BAF'},
  {key:'BOOKING',value:'BOOKING',text:'BOOKING'},
  {key:'CAF',value:'CAF',text:'CAF'},
  {key:'CCLR',value:'CCLR',text:'CCLR'},
  {key:'CCLR OVR',value:'CCLR OVR',text:'CCLR OVR'},
  {key:'CCLR-1',value:'CCLR-1',text:'CCLR-1'},
  {key:'CCLR-FRONT',value:'CCLR-FRONT',text:'CCLR-FRONT'},
  {key:'CFSCLN',value:'CFSCLN',text:'CFSCLN'},
  {key:'CFSCUST',value:'CFSCUST',text:'CFSCUST'},
  {key:'CFSFUMI',value:'CFSFUMI',text:'CFSFUMI'},
  {key:'CFSINSP',value:'CFSINSP',text:'CFSINSP'},
  {key:'CFSPACK',value:'CFSPACK',text:'CFSPACK'},
  {key:'CFSQUAR',value:'CFSQUAR',text:'CFSQUAR'},
  {key:'CFSQUP',value:'CFSQUP',text:'CFSQUP'},
  {key:'CFSSTM',value:'CFSSTM',text:'CFSSTM'},
  {key:'CFSSTOR',value:'CFSSTOR',text:'CFSSTOR'},
  {key:'CFSTGT',value:'CFSTGT',text:'CFSTGT'},
  {key:'CFSUNPA',value:'CFSUNPA',text:'CFSUNPA'},
  {key:'CFSWASH',value:'CFSWASH',text:'CFSWASH'},
  {key:'CLEART',value:'CLEART',text:'CLEART'},
  {key:'CLEART-FRO',value:'CLEART-FRO',text:'CLEART-FRO'},
  {key:'COLFE',value:'COLFE',text:'COLFE'},
  {key:'COMEQ-FA',value:'COMEQ-FA',text:'COMEQ-FA'},
  {key:'COSTPACK',value:'COSTPACK',text:'COSTPACK'},
  {key:'CUPBOIT',value:'CUPBOIT',text:'CUPBOIT'},
  {key:'CUPBOIT-1',value:'CUPBOIT-1',text:'CUPBOIT-1'},
  {key:'CUPBOIT-FR',value:'CUPBOIT-FR',text:'CUPBOIT-FR'},
  {key:'CUSDEF',value:'CUSDEF',text:'CUSDEF'},
  {key:'CUSDSB',value:'CUSDSB',text:'CUSDSB'},
  {key:'DADF',value:'DADF',text:'DADF'},
  {key:'DAQF',value:'DAQF',text:'DAQF'},
  {key:'DAWB',value:'DAWB',text:'DAWB'},
  {key:'DBILL',value:'DBILL',text:'DBILL'},
  {key:'DBILL-FRON',value:'DBILL-FRON',text:'DBILL-FRON'},
  {key:'DCAA',value:'DCAA',text:'DCAA'},
  {key:'DCART',value:'DCART',text:'DCART'},
  {key:'DCARTF',value:'DCARTF',text:'DCARTF'},
  {key:'DCARTP',value:'DCARTP',text:'DCARTP'},
  {key:'DCDEM',value:'DCDEM',text:'DCDEM'},
  {key:'DCDET',value:'DCDET',text:'DCDET'},
  {key:'DCDET-FRON',value:'DCDET-FRON',text:'DCDET-FRON'},
  {key:'DDDGF',value:'DDDGF',text:'DDDGF'},
  {key:'DDOC',value:'DDOC',text:'DDOC'},
  {key:'DDOC-FRONT',value:'DDOC-FRONT',text:'DDOC-FRONT'},
  {key:'DEAFRE',value:'DEAFRE',text:'DEAFRE'},
  {key:'DECARTFP',value:'DECARTFP',text:'DECARTFP'},
  {key:'DEHC',value:'DEHC',text:'DEHC'},
  {key:'DEVANT',value:'DEVANT',text:'DEVANT'},
  {key:'DFSC',value:'DFSC',text:'DFSC'},
  {key:'DFUMI',value:'DFUMI',text:'DFUMI'},
  {key:'DISTEXP',value:'DISTEXP',text:'DISTEXP'},
  {key:'DITF',value:'DITF',text:'DITF'},
  {key:'DLAB',value:'DLAB',text:'DLAB'},
  {key:'DNOTE',value:'DNOTE',text:'DNOTE'},
  {key:'DNOTE-FRON',value:'DNOTE-FRON',text:'DNOTE-FRON'},
  {key:'DOF',value:'DOF',text:'DOF'},
  {key:'DOF-FRONT',value:'DOF-FRONT',text:'DOF-FRONT'},
  {key:'DPCH',value:'DPCH',text:'DPCH'},
  {key:'DQUAR',value:'DQUAR',text:'DQUAR'},
  {key:'DSEC',value:'DSEC',text:'DSEC'},
  {key:'DSTOR',value:'DSTOR',text:'DSTOR'},
  {key:'DTHC',value:'DTHC',text:'DTHC'},
  {key:'DTSE',value:'DTSE',text:'DTSE'},
  {key:'DUE&SUS',value:'DUE&SUS',text:'DUE&SUS'},
  {key:'DUE&SUS-1',value:'DUE&SUS-1',text:'DUE&SUS-1'},
  {key:'DUT&TX',value:'DUT&TX',text:'DUT&TX'},
  {key:'DWHARF',value:'DWHARF',text:'DWHARF'},
  {key:'ECCLR',value:'ECCLR',text:'ECCLR'},
  {key:'EFAF',value:'EFAF',text:'EFAF'},
  {key:'FNBKCHG',value:'FNBKCHG',text:'FNBKCHG'},
  {key:'FNLNINT',value:'FNLNINT',text:'FNLNINT'},
  {key:'FNODINT',value:'FNODINT',text:'FNODINT'},
  {key:'FRT',value:'FRT',text:'FRT'},
  {key:'FRTLOC',value:'FRTLOC',text:'FRTLOC'},
  {key:'FRTLOC_CPC',value:'FRTLOC_CPC',text:'FRTLOC_CPC'},
  {key:'FRTLOC-FRO',value:'FRTLOC-FRO',text:'FRTLOC-FRO'},
  {key:'FRTOTH',value:'FRTOTH',text:'FRTOTH'},
  {key:'FRTOTH-1',value:'FRTOTH-1',text:'FRTOTH-1'},
  {key:'FRTOTH-1-F',value:'FRTOTH-1-F',text:'FRTOTH-1-F'},
  {key:'FRTOTH-FRO',value:'FRTOTH-FRO',text:'FRTOTH-FRO'},
  {key:'FRTOVR',value:'FRTOVR',text:'FRTOVR'},
  {key:'FSC',value:'FSC',text:'FSC'},
  {key:'GASLP',value:'GASLP',text:'GASLP'},
  {key:'GOODINS',value:'GOODINS',text:'GOODINS'},
  {key:'GOODINS-WF',value:'GOODINS-WF',text:'GOODINS-WF'},
  {key:'GUARA',value:'GUARA',text:'GUARA'},
  {key:'HAND',value:'HAND',text:'HAND'},
  {key:'IATA',value:'IATA',text:'IATA'},
  {key:'IATA - 16',value:'IATA - 16',text:'IATA - 16'},
  {key:'IATA 0',value:'IATA 0',text:'IATA 0'},
  {key:'IMMEX',value:'IMMEX',text:'IMMEX'},
  {key:'IMMEX-WFS',value:'IMMEX-WFS',text:'IMMEX-WFS'},
  {key:'INSUR',value:'INSUR',text:'INSUR'},
  {key:'INSUR-FRON',value:'INSUR-FRON',text:'INSUR-FRON'},
  {key:'INTERNET-F',value:'INTERNET-F',text:'INTERNET-F'},
  {key:'ITCELL',value:'ITCELL',text:'ITCELL'},
  {key:'ITCELL-1',value:'ITCELL-1',text:'ITCELL-1'},
  {key:'ITCOMP-FRO',value:'ITCOMP-FRO',text:'ITCOMP-FRO'},
  {key:'ITCOMPSUP',value:'ITCOMPSUP',text:'ITCOMPSUP'},
  {key:'ITCOPSUP-F',value:'ITCOPSUP-F',text:'ITCOPSUP-F'},
  {key:'ITINTERNET',value:'ITINTERNET',text:'ITINTERNET'},
  {key:'ITPHONE',value:'ITPHONE',text:'ITPHONE'},
  {key:'ITSERV',value:'ITSERV',text:'ITSERV'},
  {key:'ITSERV-1',value:'ITSERV-1',text:'ITSERV-1'},
  {key:'ITWEB',value:'ITWEB',text:'ITWEB'},
  {key:'KEYMAN',value:'KEYMAN',text:'KEYMAN'},
  {key:'LIABINS',value:'LIABINS',text:'LIABINS'},
  {key:'LOCFOO',value:'LOCFOO',text:'LOCFOO'},
  {key:'LOCFOO-FRO',value:'LOCFOO-FRO',text:'LOCFOO-FRO'},
  {key:'LOCFOO-WH',value:'LOCFOO-WH',text:'LOCFOO-WH'},
  {key:'LOOCFOO-FR',value:'LOOCFOO-FR',text:'LOOCFOO-FR'},
  {key:'MTADV',value:'MTADV',text:'MTADV'},
  {key:'MTADV-FRON',value:'MTADV-FRON',text:'MTADV-FRON'},
  {key:'MTBROC',value:'MTBROC',text:'MTBROC'},
  {key:'MTBROC-1',value:'MTBROC-1',text:'MTBROC-1'},
  {key:'MTBROC-FRO',value:'MTBROC-FRO',text:'MTBROC-FRO'},
  {key:'MTPROMO',value:'MTPROMO',text:'MTPROMO'},
  {key:'MTPROMO-1',value:'MTPROMO-1',text:'MTPROMO-1'},
  {key:'MTPROMO-FR',value:'MTPROMO-FR',text:'MTPROMO-FR'},
  {key:'MVGAS',value:'MVGAS',text:'MVGAS'},
  {key:'MVGAS-1',value:'MVGAS-1',text:'MVGAS-1'},
  {key:'MVGAS-FRON',value:'MVGAS-FRON',text:'MVGAS-FRON'},
  {key:'MVINS',value:'MVINS',text:'MVINS'},
  {key:'MVMTN',value:'MVMTN',text:'MVMTN'},
  {key:'MVMTN-1',value:'MVMTN-1',text:'MVMTN-1'},
  {key:'MVMTN-FR',value:'MVMTN-FR',text:'MVMTN-FR'},
  {key:'MVPARK',value:'MVPARK',text:'MVPARK'},
  {key:'MVPARK-FRO',value:'MVPARK-FRO',text:'MVPARK-FRO'},
  {key:'MVRENT',value:'MVRENT',text:'MVRENT'},
  {key:'MVRENT-1',value:'MVRENT-1',text:'MVRENT-1'},
  {key:'MVRENT-FRO',value:'MVRENT-FRO',text:'MVRENT-FRO'},
  {key:'NC- FR',value:'NC- FR',text:'NC- FR'},
  {key:'NC- FR-1',value:'NC- FR-1',text:'NC- FR-1'},
  {key:'NC- FR-2',value:'NC- FR-2',text:'NC- FR-2'},
  {key:'NC- FR-FRO',value:'NC- FR-FRO',text:'NC- FR-FRO'},
  {key:'NC- PO',value:'NC- PO',text:'NC- PO'},
  {key:'NC-ADUTIL',value:'NC-ADUTIL',text:'NC-ADUTIL'},
  {key:'NC-AG',value:'NC-AG',text:'NC-AG'},
  {key:'NC-AG-1',value:'NC-AG-1',text:'NC-AG-1'},
  {key:'NC-CA',value:'NC-CA',text:'NC-CA'},
  {key:'NC-CO',value:'NC-CO',text:'NC-CO'},
  {key:'NC-DM',value:'NC-DM',text:'NC-DM'},
  {key:'NC-DM-1',value:'NC-DM-1',text:'NC-DM-1'},
  {key:'NC-DNOTE',value:'NC-DNOTE',text:'NC-DNOTE'},
  {key:'NC-DNOTE-1',value:'NC-DNOTE-1',text:'NC-DNOTE-1'},
  {key:'NC-FS',value:'NC-FS',text:'NC-FS'},
  {key:'NC-GO',value:'NC-GO',text:'NC-GO'},
  {key:'NC-GO-1',value:'NC-GO-1',text:'NC-GO-1'},
  {key:'NC-HA',value:'NC-HA',text:'NC-HA'},
  {key:'NC-HA-1',value:'NC-HA-1',text:'NC-HA-1'},
  {key:'NC-IATA',value:'NC-IATA',text:'NC-IATA'},
  {key:'NC-OT',value:'NC-OT',text:'NC-OT'},
  {key:'NC-OT-1',value:'NC-OT-1',text:'NC-OT-1'},
  {key:'NC-RENTBU',value:'NC-RENTBU',text:'NC-RENTBU'},
  {key:'NC-TS',value:'NC-TS',text:'NC-TS'},
  {key:'NC-WADM',value:'NC-WADM',text:'NC-WADM'},
  {key:'NC-WCCF',value:'NC-WCCF',text:'NC-WCCF'},
  {key:'NC-WCTG',value:'NC-WCTG',text:'NC-WCTG'},
  {key:'NC-WLOA',value:'NC-WLOA',text:'NC-WLOA'},
  {key:'NC-WLOA-1',value:'NC-WLOA-1',text:'NC-WLOA-1'},
  {key:'NC-WLOA-FR',value:'NC-WLOA-FR',text:'NC-WLOA-FR'},
  {key:'NC-WLOA-R',value:'NC-WLOA-R',text:'NC-WLOA-R'},
  {key:'NC-WLOA-R1',value:'NC-WLOA-R1',text:'NC-WLOA-R1'},
  {key:'ND',value:'ND',text:'ND'},
  {key:'ND-FRONT',value:'ND-FRONT',text:'ND-FRONT'},
  {key:'NOMINA',value:'NOMINA',text:'NOMINA'},
  {key:'OAQF',value:'OAQF',text:'OAQF'},
  {key:'OAWB',value:'OAWB',text:'OAWB'},
  {key:'OBILL',value:'OBILL',text:'OBILL'},
  {key:'OCART',value:'OCART',text:'OCART'},
  {key:'OCDEM',value:'OCDEM',text:'OCDEM'},
  {key:'OCDET',value:'OCDET',text:'OCDET'},
  {key:'ODANG',value:'ODANG',text:'ODANG'},
  {key:'ODOC',value:'ODOC',text:'ODOC'},
  {key:'OFUMI',value:'OFUMI',text:'OFUMI'},
  {key:'ONOTE',value:'ONOTE',text:'ONOTE'},
  {key:'ONOTE-FRON',value:'ONOTE-FRON',text:'ONOTE-FRON'},
  {key:'OPCH',value:'OPCH',text:'OPCH'},
  {key:'OPCH-FRONT',value:'OPCH-FRONT',text:'OPCH-FRONT'},
  {key:'OQUAR',value:'OQUAR',text:'OQUAR'},
  {key:'OQUAR-FRON',value:'OQUAR-FRON',text:'OQUAR-FRON'},
  {key:'OSEC',value:'OSEC',text:'OSEC'},
  {key:'OSTOR',value:'OSTOR',text:'OSTOR'},
  {key:'OTHC',value:'OTHC',text:'OTHC'},
  {key:'OTHER',value:'OTHER',text:'OTHER'},
  {key:'OTHER INCO',value:'OTHER INCO',text:'OTHER INCO'},
  {key:'OTHER-1',value:'OTHER-1',text:'OTHER-1'},
  {key:'OTHER-2',value:'OTHER-2',text:'OTHER-2'},
  {key:'OTHER-FRON',value:'OTHER-FRON',text:'OTHER-FRON'},
  {key:'OTHER-WH',value:'OTHER-WH',text:'OTHER-WH'},
  {key:'OTSE',value:'OTSE',text:'OTSE'},
  {key:'OVERW',value:'OVERW',text:'OVERW'},
  {key:'PFACC',value:'PFACC',text:'PFACC'},
  {key:'PFACC-1',value:'PFACC-1',text:'PFACC-1'},
  {key:'PFCSTN',value:'PFCSTN',text:'PFCSTN'},
  {key:'PFCSTN-FFW',value:'PFCSTN-FFW',text:'PFCSTN-FFW'},
  {key:'PFCSTN-WFS',value:'PFCSTN-WFS',text:'PFCSTN-WFS'},
  {key:'POTFEE',value:'POTFEE',text:'POTFEE'},
  {key:'PS',value:'PS',text:'PS'},
  {key:'PS-1',value:'PS-1',text:'PS-1'},
  {key:'PSS',value:'PSS',text:'PSS'},
  {key:'RECE',value:'RECE',text:'RECE'},
  {key:'RECE-FRONT',value:'RECE-FRONT',text:'RECE-FRONT'},
  {key:'REIT',value:'REIT',text:'REIT'},
  {key:'REMV',value:'REMV',text:'REMV'},
  {key:'REMV-FRONT',value:'REMV-FRONT',text:'REMV-FRONT'},
  {key:'RENTBU',value:'RENTBU',text:'RENTBU'},
  {key:'RENTBU-FRO',value:'RENTBU-FRO',text:'RENTBU-FRO'},
  {key:'RENTEPO',value:'RENTEPO',text:'RENTEPO'},
  {key:'RENTMOB',value:'RENTMOB',text:'RENTMOB'},
  {key:'RENTMON',value:'RENTMON',text:'RENTMON'},
  {key:'RENTMON-FR',value:'RENTMON-FR',text:'RENTMON-FR'},
  {key:'RENTTE',value:'RENTTE',text:'RENTTE'},
  {key:'REOE',value:'REOE',text:'REOE'},
  {key:'REOE-FRONT',value:'REOE-FRONT',text:'REOE-FRONT'},
  {key:'SE',value:'SE',text:'SE'},
  {key:'TCTGF',value:'TCTGF',text:'TCTGF'},
  {key:'TCTGL',value:'TCTGL',text:'TCTGL'},
  {key:'TCTGS',value:'TCTGS',text:'TCTGS'},
  {key:'TDMG',value:'TDMG',text:'TDMG'},
  {key:'TELODG',value:'TELODG',text:'TELODG'},
  {key:'TELODG-1',value:'TELODG-1',text:'TELODG-1'},
  {key:'TELODG-FRO',value:'TELODG-FRO',text:'TELODG-FRO'},
  {key:'TEMEALS',value:'TEMEALS',text:'TEMEALS'},
  {key:'TEMEALS-1',value:'TEMEALS-1',text:'TEMEALS-1'},
  {key:'TEMEALS-FR',value:'TEMEALS-FR',text:'TEMEALS-FR'},
  {key:'TETICKET-1',value:'TETICKET-1',text:'TETICKET-1'},
  {key:'TETICKET-F',value:'TETICKET-F',text:'TETICKET-F'},
  {key:'TETICKETS',value:'TETICKETS',text:'TETICKETS'},
  {key:'TETRANT',value:'TETRANT',text:'TETRANT'},
  {key:'TETRANT-1',value:'TETRANT-1',text:'TETRANT-1'},
  {key:'TETRANT-FR',value:'TETRANT-FR',text:'TETRANT-FR'},
  {key:'TFHC',value:'TFHC',text:'TFHC'},
  {key:'TFSC',value:'TFSC',text:'TFSC'},
  {key:'THIRDPY',value:'THIRDPY',text:'THIRDPY'},
  {key:'TPLF',value:'TPLF',text:'TPLF'},
  {key:'TTSLT',value:'TTSLT',text:'TTSLT'},
  {key:'UNIF',value:'UNIF',text:'UNIF'},
  {key:'UNIF-FRONT',value:'UNIF-FRONT',text:'UNIF-FRONT'},
  {key:'VGMFE',value:'VGMFE',text:'VGMFE'},
  {key:'WADM',value:'WADM',text:'WADM'},
  {key:'WADM1',value:'WADM1',text:'WADM1'},
  {key:'WCCF',value:'WCCF',text:'WCCF'},
  {key:'WCPK',value:'WCPK',text:'WCPK'},
  {key:'WCTG',value:'WCTG',text:'WCTG'},
  {key:'WCTG OVR',value:'WCTG OVR',text:'WCTG OVR'},
  {key:'WCUP',value:'WCUP',text:'WCUP'},
  {key:'WDKT',value:'WDKT',text:'WDKT'},
  {key:'WDKT-1',value:'WDKT-1',text:'WDKT-1'},
  {key:'WDKT-1 OVR',value:'WDKT-1 OVR',text:'WDKT-1 OVR'},
  {key:'WFHC',value:'WFHC',text:'WFHC'},
  {key:'WFHCLRC',value:'WFHCLRC',text:'WFHCLRC'},
  {key:'WFHCLRP',value:'WFHCLRP',text:'WFHCLRP'},
  {key:'WHIN',value:'WHIN',text:'WHIN'},
  {key:'WHIN-FRON',value:'WHIN-FRON',text:'WHIN-FRON'},
  {key:'WHIN-FRONB',value:'WHIN-FRONB',text:'WHIN-FRONB'},
  {key:'WHIN-FRONP',value:'WHIN-FRONP',text:'WHIN-FRONP'},
  {key:'WHOUT',value:'WHOUT',text:'WHOUT'},
  {key:'WHOUT-FROB',value:'WHOUT-FROB',text:'WHOUT-FROB'},
  {key:'WHOUT-FRON',value:'WHOUT-FRON',text:'WHOUT-FRON'},
  {key:'WHOUT-FROP',value:'WHOUT-FROP',text:'WHOUT-FROP'},
  {key:'WLAB',value:'WLAB',text:'WLAB'},
  {key:'WLBL',value:'WLBL',text:'WLBL'},
  {key:'WLOA',value:'WLOA',text:'WLOA'},
  {key:'WOAS',value:'WOAS',text:'WOAS'},
  {key:'WOPK',value:'WOPK',text:'WOPK'},
  {key:'WPLT',value:'WPLT',text:'WPLT'},
  {key:'WPLT-FRON',value:'WPLT-FRON',text:'WPLT-FRON'},
  {key:'WRED',value:'WRED',text:'WRED'},
  {key:'WRET',value:'WRET',text:'WRET'},
  {key:'WRMS',value:'WRMS',text:'WRMS'},
  {key:'WRMS-1',value:'WRMS-1',text:'WRMS-1'},
  {key:'WRMS-FR-1',value:'WRMS-FR-1',text:'WRMS-FR-1'},
  {key:'WRMS-FRONT',value:'WRMS-FRONT',text:'WRMS-FRONT'},
  {key:'WRSH',value:'WRSH',text:'WRSH'},
  {key:'WSTG',value:'WSTG',text:'WSTG'},
  {key:'WSTG4PL',value:'WSTG4PL',text:'WSTG4PL'},
  {key:'WSTG4PL-FR',value:'WSTG4PL-FR',text:'WSTG4PL-FR'},
  {key:'WSTG-FRONT',value:'WSTG-FRONT',text:'WSTG-FRONT'},
]
