import { exportarCWConstants, chargeCodeConstants} from  '../modules/CargoWise'

export function cw(state = {}, action){
  switch(action.type){
    case exportarCWConstants.GET_AGENTES_REQUEST:
      return {
        ...state,
        steps: ['active','',''],
        loading: "loading"
      }
    case exportarCWConstants.GET_AGENTES_SUCCESS:
      return {
        ...state,
        steps: ['active','',''],
        loading: "",
        agentes: action.agentes
      }
    case exportarCWConstants.GET_AGENTES_FAILURE:
      return {
        ...state,
        steps: ['active','',''],
      }
    case exportarCWConstants.CREATE_CSV_REQUEST:
      return{
        steps: ['','active',''],
        loading: "loading",
      }
    case exportarCWConstants.CREATE_CSV_SUCCESS:
      return{
        steps: ['','active',''],
        facturasCSV: action.facturasCSV
      }
    case exportarCWConstants.CREATE_CSV_FAIULRE:
      return{
        steps: ['','active',''],
      }
    case exportarCWConstants.CHANGE_STEP_1:
      return {
        steps: ['active','','']
      }
    case exportarCWConstants.CHANGE_STEP_2:
      return {
        ...state,
        steps: ['','active','']
      }
    case exportarCWConstants.CHANGE_STEP_3:
      return {
        ...state,
        steps: ['','','active']
      }
      case chargeCodeConstants.CODE_LOAD_REQUEST:
        return {
          ...state,
          cargarCode: "loading",
          eraseList: true,
          conceptos: null
        }
      case chargeCodeConstants.CODE_LOAD_SUCCESS:
        return {
          ...state,
          cargarCode: "",
          eraseLista: false,
          conceptos: null
        }
      case chargeCodeConstants.CODE_LOAD_FAILURE:
        return {
          ...state,
          cargarCode: "",
        }
      case chargeCodeConstants.GET_CONCEPTOS_REQUEST:
        return {
          cargandoConceptos: true
        }
      case chargeCodeConstants.GET_CONCEPTOS_SUCCESS:
        return {
          cargandoConceptos: false,
          conceptos: action.response
        }
      case chargeCodeConstants.GET_CONCEPTOS_FAILURE:
        return {
          cargandoConceptos: false,
        }
    default:
      return {}
  }
}
