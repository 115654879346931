import React, { Component } from 'react';
import { Table, Header, Grid, Dropdown, Icon,Button } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import { connect } from 'react-redux';
import { reporteactions } from '../reporte.actions';
import DatePicker from 'react-date-picker';
import { CustomLoader } from '../../_custom';


class ReporteBanco extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaPoliza: '',
            movimientos: []
        }
    }


    componentDidMount() {
    }

    getEmpresas() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.companies) {
            let array = [];
            for (let index = 0; index < user.companies.length; index++) {
                let company = {
                    'key': user.companies[index].id,
                    'text': user.companies[index].nombre,
                    'value': user.companies[index].nombre
                }
                array.push(company);
            }
            this.setState({
                companies: array
            });
        }
    }

    onDateChangedpoliza = date => {
        let fechaform = ''
        if (date !== null) {
            let mes = (date.getMonth()) + 1;
            let dia = date.getDate()
            fechaform = (dia < 10 ? '0' + dia : dia) + '/' + (mes < 10 ? '0' + mes : mes) + '/' + date.getFullYear()
            this.setState({
                fechaPoliza: date
            })
           //this.props.filtering(fechaform)
        }
        else {
            this.setState({
                fechaPoliza: ''
            })
           // this.props.filtering()
        }
    }

    onfilter = (e) => {
        e.preventDefault()
        const { movimientos } = this.props
        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
        this.props.getReporteBanco(idUser,this.state.fechas);
        this.setState({
            movimientos: movimientos
        });
    }

    render() {
        const { requesting, creating, movimientos } = this.props;
        const { fechaPoliza } = this.state;

        if (requesting) {
            return <CustomLoader active inline='centered'
                text='Cargando cortes' />
        }
        else if (creating) {
            return <CustomLoader active inline='centered'
                text='Creando poliza' />
        }
        return (<Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='medium' dividing>
                        <Icon name='world' />
                        <Header.Content>
                            {`Reporte`}
                            <Header.Subheader>{}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column width={4}>
                    <DatePicker
                        value={fechaPoliza}
                        onChange={this.onDateChangedpoliza}

                        locale={'es-419'}
                        format={'yyyy-MM-dd'}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Button onClick={this.onfilter.bind(this)} color='blue' size='large'>
                        Obtener datos
                    </Button>
                </Grid.Column>
                {/* <Grid.Column width={4}>
                    <Dropdown
                        placeholder='Selecciona la sucursal'
                        clearable
                        options={companies}
                        selection
                        onChange={this.onSelectionChanged}
                    />
                </Grid.Column> */}
            </Grid.Row>
            <Grid.Row colums={2}>
                <Grid.Column width={11}>
                    <Table color='red' selectable  >
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Sucursal</Table.HeaderCell>
                                <Table.HeaderCell>poliza</Table.HeaderCell>
                                <Table.HeaderCell>Tipo de pago</Table.HeaderCell>
                                <Table.HeaderCell>total</Table.HeaderCell>
                                <Table.HeaderCell>Fecha de poliza</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                movimientos.map((m, i) => {
                                    const total = m.total;
                                    return (
                                        <Table.Row key={m.id} >
                                            <Table.Cell >{m.nombre}</Table.Cell>
                                            <Table.Cell >{m.poliza}</Table.Cell>
                                            <Table.Cell>{m.tipo}</Table.Cell>
                                            <Table.Cell ><Currency quantity={total} /></Table.Cell>
                                            <Table.Cell>{m.fecha_poliza} </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                                )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>)
    }
}
const mapStateToProps = (state) => {
    return {
        movimientos: state.reporte.movimientos ? state.reporte.movimientos : [],
        requesting: state.reporte.requesting,
        creating: state.reporte.creating,
        created: state.reporte.created
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReporteBanco: (idUser,fechas) => dispatch(reporteactions.getReporteBanco(idUser,fechas)),
        filtering: (filtro) => dispatch(reporteactions.filtering(filtro))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReporteBanco)