import {
  getListaFacturasUrl,
  getListaConceptosUrl,
  getAgente,
} from './'
import { uriDevelop, headers } from '../../service'

export const listasService = {
  getListaFacturas,
  getListaConceptos,
  getAgentes,
  handleResponse,
}

function handleResponse(response){
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if(!response.ok){
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getListaFacturas(selectedSupplier){
  //Validar si es Admin o Proveedor por URL
  if(window.location.href.includes('admin')){
    const requestOptions = {
        method: 'GET',
        headers: headers
      };
      return fetch(`${uriDevelop}${getListaFacturasUrl}${selectedSupplier}`, requestOptions)
        .then(handleResponse)
  } else{
    const user = localStorage.getItem('supplier')
    const requestOptions = {
        method: 'GET',
        headers: headers
      };
      const supplierData = JSON.parse(localStorage.getItem('supplier'))
      return fetch(`${uriDevelop}${getListaFacturasUrl}${supplierData.user.id_agente}`, requestOptions)
        .then(handleResponse)
  }
}

function getListaConceptos(selectecFactura){
  const requestOptions = {
    method: 'GET',
    headers: headers
  }
  return fetch(`${uriDevelop}${getListaConceptosUrl}${selectecFactura}`, requestOptions)
    .then(handleResponse)
}


function getAgentes(){
  const requestOptions = {
    method: 'GET',
    headers: headers
  };

  return fetch(`${uriDevelop}${getAgente}`, requestOptions)
    .then(handleResponse);
}
