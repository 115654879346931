import React, { Component } from 'react';
import { Button, Table, Header, Grid, Checkbox, Input, Icon, Modal, Dropdown } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { corteActions } from '../corte.actions';

import { CustomLoader } from '../../_custom';
import AbonoResponse from './AbonoResponse';

class AbonoFactura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCompany: 0,
            sumaTotales: 0,
            checkedItems: [],
            valueItems: [],
            listItems: [],
            facturas: [],
            open: false,
            fechafactura: "",
            checkfechahoy: false,
            disablecheck: false,
            fechasFacturas: ['', ''],
            nombreFiltro: "",
            totalfiltro: "",
            referenciafiltro: "",
            facturasfiltro: "",
            totalfiltro: "",
            fechascorte: ['', ''],
            disableFecha: false,
            metodopago: 0
        }
    }
    show = () => () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    check = () => this.setState({ checkfechahoy: false })

    clearState() {
        this.setState({
            open: false,
            sumaTotales: 0,
            checkedItems: [],
            valueItems: [],
            listItems: [],
            importe: 0,
            checkfechahoy: false
        });
    }

    onSelectionChanged = (e, data) => {
        this.setState({ metodopago: data.value });
    }

    componentDidMount() {
       
    }

    oncheckFechahoy = (e) => {
        this.setState({
            checkfechahoy: !this.state.checkfechahoy
        })
        var filtro = new Object();
        let fechasformateadas = [];
        if (this.state.checkfechahoy == false) {
            let fechaform = [];
            for (let index = 1; index <= 2; index++) {
                let dat = new Date()
                let mes = (dat.getMonth()) + 1;
                let dia = dat.getDate()
                fechaform = dat.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
                fechasformateadas.push(fechaform);
            }
            filtro.fechasFacturas = fechasformateadas;
            this.setState({
                fechasFacturas: fechasformateadas,
            });
            //this.props.filtering(filtro)
        }
        else if (this.state.checkfechahoy == true) {
            this.setState({
                fechasFacturas: ['', '']
            })
           //this.props.filtering()
        }
    }

    onChange = (e, item) => {
        const { checkedItems, listItems } = this.state;
        let itemChecked = { key: item.key, total: item.total, serie: item.serie, folio: item.folio, acumulado: item.acumulado, uuid: item.uuid }
        let sumaActual = 0;
        if (listItems.length !== 0)
            listItems.forEach(a => {
                sumaActual += parseFloat(a.importe_pago)
            });
        let Totalchecked = parseFloat(itemChecked.total - itemChecked.acumulado);
        let nextmonto = parseFloat(sumaActual) + parseFloat(Totalchecked);

        if (checkedItems.length === 0) {
            itemChecked.importe_pago = Totalchecked;
            itemChecked.tipo = 'total';
            this.setState((prevState) =>
                ({
                    sumaTotales: nextmonto,
                    checkedItems: [...prevState.checkedItems, itemChecked],
                    listItems: [...prevState.listItems, itemChecked]
                })
            )
        }
        else if (!checkedItems.some((i) => i.key === itemChecked.key)) {
            itemChecked.importe_pago = Totalchecked;
            itemChecked.tipo = 'total';
            this.setState((prevState) =>
                ({
                    sumaTotales: nextmonto,
                    checkedItems: [...prevState.checkedItems, itemChecked],
                    listItems: [...prevState.listItems, itemChecked]
                })
            )
        }
        else {
            this.setState((prevState) =>
                ({
                    sumaTotales: Math.round(sumaActual - Totalchecked),
                    checkedItems: prevState.checkedItems.filter(i => i.key !== itemChecked.key),
                    listItems: prevState.listItems.filter(i => i.key !== itemChecked.key)
                })
            );
        }
    }


    onSubmit = () => {
        let data = {
            ...this.state
        }
        this.clearState();
        this.props.crearAbono(data);
    }

    onDateChanged = date => {
        const { referenciafiltro, nombreFiltro, facturasfiltro, totalfiltro } = this.state
        let fechasformateadas = [];
        var filtro = new Object();
        if (date !== null) {
            let fechaform;
            date.forEach(dat => {
                let mes = (dat.getMonth()) + 1;
                let dia = dat.getDate()
                fechaform = dat.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
                fechasformateadas.push(fechaform);
            });
            this.setState({
                fechasFacturas: fechasformateadas,
                disablecheck: true
            })
            filtro.fechasFacturas = fechasformateadas;
            if (referenciafiltro !== '')
                filtro.referencia = referenciafiltro;
            if (facturasfiltro !== '')
                filtro.facturas = facturasfiltro;
            if (nombreFiltro !== '')
                filtro.nombrefiltro = nombreFiltro;
            if (totalfiltro !== '')
                filtro.total = totalfiltro;
            //this.props.filtering(filtro)
        }
        else {
            this.setState({
                fechasFacturas: ["", ""],
                disablecheck: false
            })
            if (nombreFiltro !== '')
                filtro.nombrefiltro = nombreFiltro;
            if (referenciafiltro !== '')
                filtro.referencia = referenciafiltro;
            if (facturasfiltro !== '')
                filtro.facturas = facturasfiltro;
            if (totalfiltro !== '')
                filtro.total = totalfiltro;
            //this.props.filtering(filtro)
        }
    }

    handleInputChangeNombre = (e) => {
        const { fechasFacturas, facturasfiltro, referenciafiltro, totalfiltro } = this.state;
        var filtro = new Object();
        let amount = e.target.value;
        this.setState({ nombreFiltro: amount })
        if (amount !== '')
            filtro.nombrefiltro = amount;
        if (referenciafiltro !== '')
            filtro.referencia = referenciafiltro;
        if (facturasfiltro !== '')
            filtro.facturas = facturasfiltro;
        if (totalfiltro !== '')
            filtro.total = totalfiltro;
        if (fechasFacturas.length > 0)
            if (fechasFacturas[0] != "")
                filtro.fechasFacturas = fechasFacturas;
        this.props.filtering(filtro)
    }

    handleInputChangeReferencia = (e) => {
        const { fechasFacturas, nombreFiltro, facturasfiltro, totalfiltro } = this.state;
        var filtro = new Object();
        let amount = e.target.value;
        this.setState({ referenciafiltro: amount })
        if (amount !== '')
            filtro.referencia = amount;
        if (nombreFiltro !== '')
            filtro.nombrefiltro = nombreFiltro;
        if (facturasfiltro !== '')
            filtro.facturas = facturasfiltro;
        if (totalfiltro !== '')
            filtro.total = totalfiltro;
        if (fechasFacturas.length > 0)
            if (fechasFacturas[0] != "")
                filtro.fechasFacturas = fechasFacturas;
        this.props.filtering(filtro)
    }

    handleInputChangefacturas = (e) => {
        const { fechasFacturas, nombreFiltro, referenciafiltro, totalfiltro } = this.state;
        var filtro = new Object();
        let amount = e.target.value;
        this.setState({ facturasfiltro: amount })
        if (amount !== '')
            filtro.facturas = amount;
        if (nombreFiltro !== '')
            filtro.nombrefiltro = nombreFiltro;
        if (referenciafiltro !== '')
            filtro.referencia = referenciafiltro;
        if (totalfiltro !== '')
            filtro.total = totalfiltro;
        if (fechasFacturas.length > 0)
            if (fechasFacturas[0] != "")
                filtro.fechasFacturas = fechasFacturas;
        this.props.filtering(filtro)
    }

    handleInputChangetotal = (e) => {
        const { fechasFacturas, nombreFiltro, referenciafiltro } = this.state;
        var filtro = new Object();
        let amount = e.target.value;
        this.setState({ totalfiltro: amount })
        if (amount !== '')
            filtro.total = amount;
        if (nombreFiltro !== '')
            filtro.nombrefiltro = nombreFiltro;
        if (referenciafiltro !== '')
            filtro.referencia = referenciafiltro;
        if (fechasFacturas.length > 0)
            if (fechasFacturas[0] != "")
                filtro.fechasFacturas = fechasFacturas;
        this.props.filtering(filtro)
    }

    keyHandler = (e) => {
        let isnum = (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105);
        let ispoint = e.keyCode === 110 || e.keyCode === 190;
        let isbackspace = e.keyCode === 8;
        if (!isnum && !ispoint && !isbackspace) {
            e.preventDefault();
        }
    }

    handleInputChange = (e, f) => {
        let item = { key: f.key, total: f.total, serie: f.serie, folio: f.folio, acumulado: f.acumulado, uuid: f.uuid }
        let amount = e.target.value;
        const name = e.target.name;
        const { valueItems, listItems, monto } = this.state;
        let index = -1;
        let sumaActual = 0;
        if (listItems.length !== 0) {
            index = valueItems.map(function (e) { return e.key; }).indexOf(name);
            listItems.forEach(a => {
                sumaActual += parseFloat(a.importe_pago)
            });
        }

        let nextmonto = parseFloat(sumaActual) + parseFloat(amount);
        if (valueItems.length === 0) {
            item.importe_pago = parseFloat(amount);
            item.tipo = 'abono';
            this.setState((prevState) =>
                ({
                    sumaTotales: nextmonto,
                    valueItems: [...prevState.valueItems, item],
                    listItems: [...prevState.listItems, item],
                    monto: amount
                })
            )
        }
        else if (valueItems.some((i) => i.key === item.key && amount !== '')) {
            let vanterior = parseFloat(valueItems[index].importe_pago);
            item.importe_pago = parseFloat(amount);
            item.tipo = 'abono';
            this.setState((prevState) =>
                ({
                    sumaTotales: (parseFloat(sumaActual) - vanterior) + parseFloat(amount),
                    valueItems: valueItems.map(a => {
                        var returnValue = { ...a };
                        if (a.key == name) {
                            returnValue.importe_pago = amount;
                        }
                        return returnValue
                    }),
                    listItems: listItems.map(a => {
                        var returnValue = { ...a };
                        if (a.key == name) {
                            returnValue.importe_pago = amount;
                        }
                        return returnValue
                    })
                })
            )
        }
        else if (!valueItems.some((i) => i.key === item.key)) {
            item.importe_pago = parseFloat(amount);
            item.tipo = 'abono';
            this.setState((prevState) =>
                ({
                    sumaTotales: parseFloat(nextmonto),
                    valueItems: [...prevState.valueItems, item],
                    listItems: [...prevState.listItems, item],
                    monto: amount
                })
            )
        }
        else if (valueItems.some((i) => i.key === item.key && i.importe_pago === NaN || amount === '')) {
            let vanterior = parseFloat(valueItems[index].importe_pago);
            this.setState((prevState) =>
                ({
                    sumaTotales: sumaActual - parseFloat(vanterior),
                    valueItems: prevState.valueItems.filter(i => i.key !== item.key),
                    listItems: prevState.listItems.filter(i => i.key !== item.key)
                })
            )
        }

    }

    onDateChangedCorte = date => {
        let fechasformateadas = [];
        if (date !== null) {
            let fechaform;
            date.forEach(dat => {
                let mes = (dat.getMonth()) + 1;
                let dia = dat.getDate()
                fechaform = dat.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
                fechasformateadas.push(fechaform);
            });
            this.setState({
                fechascorte: fechasformateadas,
                disableFecha: true
            })
        }
        else {
            this.setState({
                fechascorte: ["", ""],
                disableFecha: false
            })
        }
    }

    onfilter = (e) => {
        e.preventDefault()
        let idempresa = JSON.parse(localStorage.getItem("company")).id;
        this.props.getFacturasCorte(idempresa,this.state.fechasFacturas);
        this.setState({
            facturas: this.props.facturas,
            idCompany: idempresa
        });
    }

    render() {
        const { creating, created, requesting, facturas, formaspago } = this.props;
        const { listItems, valueItems, checkedItems, sumaTotales, open, disablecheck, checkfechahoy, metodopago, fechasFacturas, nombreFiltro, disableFecha, referenciafiltro, totalfiltro, facturasfiltro, fechascorte } = this.state;
        if (creating) {
            return <CustomLoader active inline='centered'
                text='Creando abono'
            />
        }
        if (requesting) {
            return <CustomLoader active inline='centered'
                text='Cargando facturas'
            />
        }
        else if (created) {
            return <AbonoResponse />
        }
        return (<Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='medium' dividing>
                        <Icon name='money bill alternate' />
                        <Header.Content>
                            {`Facturas pagos directos: `}
                            <Header.Subheader>{}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column>
                    <div>
                        <p>- Selecciona el Método de Pago: </p>
                        <Dropdown
                            placeholder='Selecciona el método de pago'
                            fluid
                            selection
                            options={formaspago}
                            defaultValue={metodopago}
                            onChange={this.onSelectionChanged}
                        />
                    </div>
                </Grid.Column>
                <Grid.Column>
                    <Header size='small' >Suma Total: <Currency quantity={sumaTotales} /></Header>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button onClick={this.show(true)} color='green' size='large' disabled={!(listItems.length !== 0 && metodopago !== 0)}>
                        Crear abono
                </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column>
                    <Header size='tiny' >Filtro fecha  :
                    <DateRangePicker
                            onChange={this.onDateChanged}
                            value={fechasFacturas}
                            locale={'es-419'}
                            format={'yyyy-MM-dd'}
                            disabled={this.state.checkfechahoy == true}
                        />
                    </Header>
                </Grid.Column>
                <Grid.Column>
                    <Checkbox toggle
                        label='Fecha de hoy'
                        disabled={disablecheck}
                        onChange={(e) => this.oncheckFechahoy(e)}
                        checked={checkfechahoy} />
                </Grid.Column>
                <Grid.Column>
                    <Button onClick={this.onfilter.bind(this)} color='blue' size='large'>
                        Obtener datos
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header size='tiny' dividing>
                        <Icon name='search' />
                        <Header.Content>
                            {`Filtro de datos mostrados`}
                            <Header.Subheader>{ }</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
                <Grid.Column >
                    <Input icon='eye' type='text'
                        placeholder='Nombre de cliente...'
                        onChange={(e) => this.handleInputChangeNombre(e)}
                        value={nombreFiltro}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
                <Grid.Column>
                    <Input icon='search' type='text'
                        placeholder='Total...'
                        onKeyDown={this.keyHandler}
                        onChange={(e) => this.handleInputChangetotal(e)}
                        value={totalfiltro}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
                <Grid.Column>
                    <Input icon='search' type='text'
                        placeholder='Referencia...'
                        onChange={(e) => this.handleInputChangeReferencia(e)}
                        onKeyDown={this.keyHandler}
                        value={referenciafiltro}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
                <Grid.Column >
                    <Input icon='eye' type='text'
                        placeholder='facturas...'
                        onChange={(e) => this.handleInputChangefacturas(e)}
                        value={facturasfiltro}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row colums={2}>
                <Grid.Column width={10}>
                    <Table color='teal' selectable  >
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Fecha Factura</Table.HeaderCell>
                                <Table.HeaderCell>Serie-Folio</Table.HeaderCell>
                                <Table.HeaderCell>Nombre Cliente</Table.HeaderCell>
                                <Table.HeaderCell>Moneda</Table.HeaderCell>
                                <Table.HeaderCell>Total Factura</Table.HeaderCell>
                                <Table.HeaderCell>Abonado</Table.HeaderCell>
                                <Table.HeaderCell>referencias</Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                facturas.map((f, i) =>
                                    (
                                        <Table.Row key={f.key} >
                                            <Table.Cell >{f.fecha.split(" ")[0]}</Table.Cell>
                                            <Table.Cell>{f.folio_serie}</Table.Cell>
                                            <Table.Cell>{f.nombre_cliente}</Table.Cell>
                                            <Table.Cell>{f.moneda}</Table.Cell>
                                            <Table.Cell><Currency quantity={f.total} /></Table.Cell>
                                            <Table.Cell><Currency quantity={f.acumulado} /></Table.Cell>
                                            <Table.Cell>{f.referencia}</Table.Cell>
                                            <Table.Cell>
                                                <Input icon='money' type='text' name={f.key}
                                                    placeholder='Monto del pago...'
                                                    onChange={(e, data) => this.handleInputChange(e, f)}
                                                    disabled={checkedItems.some((i) => i.key === f.key)}
                                                    onKeyDown={this.keyHandler}
                                                    //value={monto}
                                                    pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Checkbox toggle
                                                    name={f.key}
                                                    disabled={(valueItems.some((i) => i.key === f.key))}
                                                    onChange={(e, data) => this.onChange(e, f)}
                                                    checked={checkedItems.some((i) => i.key === f.key)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div>
                                                    {/*JSON.stringify(this.state.valueItems)*/}
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Table inverted color='teal' selectable>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Serie-Folio</Table.HeaderCell>
                                <Table.HeaderCell>Pago</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                listItems.map((f, i) =>
                                    (
                                        <Table.Row key={f.key}>
                                            <Table.Cell>{`${f.serie}-${f.folio}`}</Table.Cell>
                                            <Table.Cell>
                                                <Currency quantity={f.importe_pago} />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
            <Modal size='tiny' open={open}>
                <Modal.Header>Confirmar abono a factura</Modal.Header>
                <Modal.Content>
                    Periodo de abono:
                </Modal.Content>
                <Modal.Actions>
                    <Table size7={'small'}>
                        <Table.Row>
                            <Table.Cell >
                                <DateRangePicker
                                    onChange={this.onDateChangedCorte}
                                    value={fechascorte}
                                    locale={'es-419'}
                                    format={'yyyy-MM-dd'}
                                />
                            </Table.Cell >
                        </Table.Row>
                    </Table>
                    <Button size='mini' negative onClick={this.close}>Cancelar</Button>
                    <Button size='mini' positive disabled={!disableFecha} onClick={this.onSubmit}>Confirmar</Button>
                </Modal.Actions>
            </Modal>
            {/*<!-- <Modal size='tiny' open={open}>
                <Modal.Header>Confirmar abono de facturas</Modal.Header>
                <Modal.Content>¿Estás seguro de crear el abono de la factura?</Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.close}>No</Button>
                    <Button positive onClick={this.onSubmit}>Confirmar</Button>
                </Modal.Actions>
            </Modal>--!> */}
        </Grid >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        facturas: state.corte.facturas ? state.corte.facturas : [],
        formaspago: state.corte.formaspago ? state.corte.formaspago : [],
        requesting: state.corte.requesting,
        creating: state.corte.creating,
        created: state.corte.created
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFacturasCorte: (idEmpresa,fechas) => dispatch(corteActions.getAll(idEmpresa,fechas)),
        crearAbono: (data) => dispatch(corteActions.crearAbonoFactura(data)),
        filtering: (fechacorte) => dispatch(corteActions.filteringFacturaAbono(fechacorte))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AbonoFactura)