import React from 'react'
import { Container, Header, Icon } from 'semantic-ui-react'
import { FacturasPendientes, ConceptosPendientes } from '.'


class CargarChargeCode extends React.Component {
  render(){
    return(
      <Container fluid>
        <Header size="small" dividing>
          <Icon name="cloud upload"/>
          <Header.Content>
            Cargar Charge-Code a Facturas
            <Header.Subheader>Aquí puedes ingresar el charge-code para cada factura.</Header.Subheader>
          </Header.Content>
        </Header>
        <ConceptosPendientes />
      </Container>
    )
  }
}

export default CargarChargeCode
