import { constants } from "fs";

export const reportConstants = {

  GETREPORTEBANCO_REQUEST: 'GetReporteBanc_Request',
  GETREPORTEBANCO_SUCCESS: 'GetReporteBanc_Success',
  GETREPORTEBANCO_FAILURE: 'GetReporteBanco_Failure',

  FILTER_RMOVIMIENTOS_CLEAR: 'FilterMovimientosClear',
  FILTER_RMOVIMIENTOS: 'FilterMovimientos',


};

export const formatDate = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZone: 'America/Los_Angeles'
};

export const getReporte = '/reporte/reporte-banco'