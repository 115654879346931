import React from 'react'
import { Grid, Accordion, Table, Form, Select, Icon, Header, Container} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { listasActions } from '../'
import { CustomLoader } from '../../_custom'
import CurrencyFormat from 'react-currency-format';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


class ListaFacturas extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedSupplier: null,
      agentes: []
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, data){
     this.props.getList(data.value);
  }

  async componentDidMount(){
    this.props.getAgentes();
    await this.props.getList();
    this.setState({
      agentes: this.props.agentes
    })
  }


  render(){
    const { requesting, facturas, agentes } = this.props;
    let options = null;
    if(agentes){ this.state.agentes = agentes }
    if(this.state.agentes){
        let {agentes} = this.state.agentes;
        console.log(agentes)
        if(agentes){
          options = agentes.map(x => {
          return({
            key: x.key,
            text: x.nombre,
            value: x.key,
          });
        });
      }
      console.log(options);
    }

    return(
      <Container fluid>
        <Header size="small" dividing>
          <Icon name="list" />
          <Header.Content>
            Lista de Facturas
            <Header.Subheader>Aquí se muestran todas las facturas cargadas por tus proveedores.</Header.Subheader>
          </Header.Content>
        </Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width="15">
              <Form>
                <h3>Filtros</h3>
                <Form.Field>
                  <Form.Group widths='equal'>
                    {
                      (window.location.href.includes('admin')) 
                        ?
                          <Form.Select options={options} selection fluid onChange={this.handleChange} fluid label="Filtrar por RFC" placeholder="Escribe el RFC del proveedor..." />
                        :
                          null
                    }
                    <Form.Input fluid label="Filtrar por Serie" placeholder="Escribe la Serie de la factura" />
                    <Form.Input fluid label="Filtrar por Folio" placeholder="Escribe el Folio de la factura" />
                    <Form.Input fluid label="Filtrar por Job" placeholder="Escribe el Job de la factura..." />
                  </Form.Group>
                  <Form.Group inline>
                    <label>Status de la Factura</label>
                    <Form.Radio
                      label='Pagada'
                    />
                    <Form.Radio
                      label='Pendiente'
                    />
                    <Form.Radio
                      label='Por validar'
                    />
                  </Form.Group>
                </Form.Field>

              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Download as XLS"
            />
            <Table id="table-to-xls" celled>
              <Table.Header>
                <Table.HeaderCell>RFC</Table.HeaderCell>
                <Table.HeaderCell>Serie y Folio</Table.HeaderCell>
                <Table.HeaderCell>Job</Table.HeaderCell>
                <Table.HeaderCell>UUID</Table.HeaderCell>
                <Table.HeaderCell>Total de la Factura</Table.HeaderCell>
                <Table.HeaderCell>Acumulado</Table.HeaderCell>
                <Table.HeaderCell>Restante</Table.HeaderCell>
                <Table.HeaderCell>Número de Abonos</Table.HeaderCell>
                <Table.HeaderCell>Complementos</Table.HeaderCell>
              </Table.Header>
                { requesting
                  ?
                    <Table.Body>
                      <Table.Row>
                        <CustomLoader />
                      </Table.Row>
                    </Table.Body>
                  :
                    (
                      <Table.Body>
                        {facturas ? (facturas.map(factura => {
                          return(
                            <Table.Row>
                              <Table.Cell>{factura.rfc}</Table.Cell>
                              <Table.Cell>{(factura.serie ? factura.serie + "-" : "") + factura.folio}</Table.Cell>
                              <Table.Cell>{factura.job}</Table.Cell>
                              <Table.Cell>{factura.uuid}</Table.Cell>
                              <Table.Cell>
                                <CurrencyFormat
                                  value={factura.total}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'} />
                              </Table.Cell>
                              <Table.Cell>
                                <CurrencyFormat
                                  value={factura.acumulado}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'} />
                              </Table.Cell>
                              <Table.Cell>
                                <CurrencyFormat
                                  value={factura.restante}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'} />
                              </Table.Cell>
                              <Table.Cell>{factura.n_abonos}</Table.Cell>
                              <Table.Cell>
                                <ul>
                                  {factura.complementos.map(complemento => {
                                    return(
                                      <li>{complemento.serie + "-" + complemento.folio}</li>
                                    )
                                  })}
                                </ul>
                              </Table.Cell>
                            </Table.Row>
                          )
                        })) : (null)
                        }
                      </Table.Body>
                    )
                    }
              </Table>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    requesting: state.listas.requesting,
    facturas: state.listas.facturas,
    agentes: state.listas.agentes
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    getList: (selectedSupplier) => dispatch(listasActions.getList(selectedSupplier)),
    getAgentes: () => dispatch(listasActions.getAgentes())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaFacturas)
