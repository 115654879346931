import { alertActions } from '../Alert';
import { xmlConstants, xmlService, options, parser, columns} from '.';

export const xmlActions = {
  cargarXML,
  showXML,
  clearXML,
  cargarJobs,
  cargarXML2,
}

function showXML(files){
  return dispatch => {
    const xmls = files;
    dispatch(show());

    function arrayBufferToString(arrayBuffer, decoderType = 'utf-8') {
      let decoder = new TextDecoder(decoderType);
      return decoder.decode(arrayBuffer);
    }

    let strings = []
    function readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    }

    async function processFile(file) {
      try {
        let xmlToJson = [];
        for(let i=0; i<file.length; i++){
          let arrayBuffer = await readFileAsync(file[i]);
          xmlToJson[i] = parser.parse(arrayBufferToString(arrayBuffer.slice(0)), options);
        }
        dispatch(request(xmlToJson));
        dispatch(success(xmlToJson));
      }
      catch (err) {
        console.log(err);
      }
    }

    processFile(xmls);
  }
  function show(){ return { type: xmlConstants.XML_SHOW, files} }
  function request(strings){ return {type: xmlConstants.XML_SHOW_REQUEST , strings } }
  function success(strings){ return { type: xmlConstants.XML_SHOW_SUCCESS, strings } }
};

function clearXML(){
  return dispatch => {
    dispatch(clear());
  }
  function clear(){ return { type: xmlConstants.XML_SHOW_CLEAR } }
};

function cargarXML2(data, jobData){
  return dispatch => {
    if(data){
      data.forEach((factura, i) => {
        factura.job = jobData[i]
      })
      let facturasFiltered = data.filter(xml => xml["cfdi:Comprobante"].TipoDeComprobante === "I" && xml["cfdi:Comprobante"].MetodoPago === 'PPD' && xml["cfdi:Comprobante"].FormaPago === '99' && xml["cfdi:Comprobante"]["cfdi:Receptor"].UsoCFDI === 'G03');
      dispatch(request());
      console.log(facturasFiltered);
      const facturas = facturasFiltered.map((x, i) => {
        const comprobante = x["cfdi:Comprobante"]
        const impuestos = comprobante["cfdi:Impuestos"]
        const conceptosFactura = comprobante["cfdi:Conceptos"]["cfdi:Concepto"]
        const id = JSON.parse(localStorage.getItem('supplier')).user.id_agente
        let trasladoFac  = impuestos.TotalImpuestosTrasladados ? impuestos.TotalImpuestosTrasladados : 0
        let retencionFac = impuestos.TotalImpuestosRetenidos   ? impuestos.TotalImpuestosRetenidos   : 0
        let conceptos = [];
        if(conceptosFactura[0]){
            conceptos = conceptosFactura.map((y) => {
            let trasladoCon = (y["cfdi:Impuestos"]["cfdi:Traslados"]       ? y["cfdi:Impuestos"]["cfdi:Traslados"]["cfdi:Traslado"].Importe    : 0);
            let retencionCon = (y["cfdi:Impuestos"]["cfdi:Retenciones"]  ? y["cfdi:Impuestos"]["cfdi:Retenciones"]["cfdi:Retencion"].Importe : 0);
            return {

              "cantidad": y.Cantidad,
  		    		"clave_producto_servicio": y.ClaveProdServ,
  		    		"clave_unidad": y.ClaveUnidad,
  		    		"descripcion": y.Descripcion,
  		    		"importe": y.Importe,
  		    		"valor_unitario": y.ValorUnitario,
  		    		"traslado": trasladoCon,
  		    		"retencion": retencionCon,
            }
          })
        } else {
          let trasladoCon = (conceptosFactura["cfdi:Impuestos"]["cfdi:Traslados"] ? conceptosFactura["cfdi:Impuestos"]["cfdi:Traslados"]["cfdi:Traslado"].Importe : 0);
          let retencionCon = (conceptosFactura["cfdi:Impuestos"]["cfdi:Retenciones"]  ? conceptosFactura["cfdi:Impuestos"]["cfdi:Retenciones"]["cfdi:Retencion"].Importe : 0);
            conceptos = {
            "cantidad": conceptosFactura.Cantidad,
            "clave_producto_servicio": conceptosFactura.ClaveProdServ,
            "clave_unidad": conceptosFactura.ClaveUnidad,
            "descripcion": conceptosFactura.Descripcion,
            "importe": conceptosFactura.Importe,
            "valor_unitario": conceptosFactura.ValorUnitario,
            "traslado": trasladoCon,
            "retencion": retencionCon,
          }
        }

        return {
          "id_agente": id,
          "conceptos":conceptos,
          "job": x.job,
          "serie": comprobante.Serie,
          "folio": comprobante.Folio,
          "uuid": comprobante["cfdi:Complemento"]["tfd:TimbreFiscalDigital"].UUID,
          "total": comprobante.Total,
          "metodo_pago": comprobante.MetodoPago,
          "moneda": comprobante.Moneda,
          "fecha": comprobante.Fecha,
          'traslado': trasladoFac,
          'retencion': retencionFac,
        }
      })

      let requestBody = {
        "facturas": facturas
      }

      console.log(requestBody);

      xmlService.cargarXML(requestBody)
        .then(
          response => {
            if(response.status){
              dispatch(alertActions.success(response.message))
              dispatch(success(response.message, facturasFiltered))
            } else{
              dispatch(failure(response.message))
              dispatch(alertActions.success(response.message))
            }
          },
          error => {
            dispatch(failure(error.toString()))
            dispatch(alertActions.error(error.toString()))
          }
        );
    }
  }

  function request(){ return { type: xmlConstants.XML_LOAD_REQUEST } }
  function success(response, facturas) { return { type: xmlConstants.XML_LOAD_SUCCESS, response, facturas } }
  function failure(response) { return { type: xmlConstants.XML_LOAD_FAILURE } }
}

function cargarXML(data, jobData){
  return dispatch => {
    if(data){
      let obData;
      let retencion = null
      let traslados = null

      // agregar el job a las facturas
      data.forEach((factura, i) => {
        factura.job = jobData[i]
      })

      let facturas = data.filter(xml => xml["cfdi:Comprobante"].TipoDeComprobante === "I" && xml["cfdi:Comprobante"].MetodoPago === 'PPD' && xml["cfdi:Comprobante"].FormaPago === '99' && xml["cfdi:Comprobante"]["cfdi:Receptor"].UsoCFDI === 'G03');
      dispatch(request());
      let rows = [];
      for(let i=0; i<facturas.length; i++){
        const comprobante = facturas[i]["cfdi:Comprobante"];
        const impuestos = comprobante["cfdi:Impuestos"];
        const conceptos = comprobante["cfdi:Conceptos"]["cfdi:Concepto"]
        let body = []
        if(conceptos[0]){
          body.push(conceptos.map(x => {
            return({
              'Cantidad': x.Cantidad,
              'ClaveProdServ': x.ClaveProdServ,
              'ClaveUnidad': x.ClaveUnidad,
              'Descripcion': x.Descripcion,
              'Importe': x.Importe,
              'ValorUnitario': x.ValorUnitario,
            })
          }))
        } else{
          body.push({
            'Cantidad': conceptos.Cantidad,
            'ClaveProdServ': conceptos.ClaveProdServ,
            'ClaveUnidad': conceptos.ClaveUnidad,
            'Descripcion': conceptos.Descripcion,
            'Importe': conceptos.Importe,
            'ValorUnitario': conceptos.ValorUnitario,
          })
        }
        body.push(facturas[i].job);
        body.push(comprobante.Serie);
        body.push(comprobante.Folio);
        body.push(comprobante["cfdi:Complemento"]["tfd:TimbreFiscalDigital"].UUID);
        body.push(comprobante.Total);
        body.push(comprobante.MetodoPago);
        body.push(comprobante.Moneda);
        body.push(comprobante.Fecha);
        if (comprobante.MetodoPago === 'PPD' && comprobante.FormaPago === '99' && comprobante["cfdi:Receptor"].UsoCFDI === 'G03') {
          rows.push(body);
        }
        if(impuestos.TotalImpuestosTrasladados){
          body.push(impuestos.TotalImpuestosTrasladados)
          traslados = true
        }

        if(impuestos.TotalImpuestosRetenidos && traslados){
          body.push(impuestos.TotalImpuestosRetenidos)
          retencion = "true"
        }

      }

      console.log(rows)
      if(!traslados && !retencion){
        obData = columns.slice(0, 7) }
      else if(traslados){
        if(retencion){ obData = columns.slice(0, 10) }
        else{ obData = columns.slice(0, 9) }
      }

      console.log(obData)
      let requestBody = {
        "idUsuarioProv" : JSON.parse(localStorage.getItem('supplier')).user.id,
        "columns": obData,
        "rows": rows
      }

      xmlService.cargarXML(requestBody)
        .then(
          response => {
            if(response.status){
              dispatch(alertActions.success(response.message))
              dispatch(success(response.message, facturas))
            } else{
              dispatch(failure(response.message))
              dispatch(alertActions.success(response.message))
            }
          },
          error => {
            dispatch(failure(error.toString()))
            dispatch(alertActions.error(error.toString()))
          }
        );
    }

  };

  function request(){ return { type: xmlConstants.XML_LOAD_REQUEST } }
  function success(response, facturas) { return { type: xmlConstants.XML_LOAD_SUCCESS, response, facturas } }
  function failure(response) { return { type: xmlConstants.XML_LOAD_FAILURE } }
};

function cargarJobs(jobs){
  return dispatch => {
    dispatch(jobCargado(jobs))
  }

  function jobCargado(jobs){ return { type: xmlConstants.XML_JOB_LOAD, jobs } }
}
