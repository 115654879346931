import { corteConstants } from '../modules/CorteCaja';


export function corte(state = {}, action) {
  switch (action.type) {
    case corteConstants.GETFACTURA_REQUEST:
      return {
        requesting: true,
      };
    case corteConstants.GETFACTURA_SUCCESS:
      return {
        facturas_all: action.data.facturas,
        facturas: action.data.facturas,
        formaspago: action.data.formapago,
        requesting: false
      };
    case corteConstants.GETFACTURA_FAILURE:
      return {
        requesting: false
      };
    case corteConstants.CREATECORTE_REQUEST:
      return {
        ...state,
        creating: true,
        requesting: true
      }
    case corteConstants.CREATECORTE_SUCCESS:
      return {
        created: true
      };
    case corteConstants.CREATECORTE_FAILURE:
      return {
        error: action.response.message,
        created: true
      };
    case corteConstants.GETLISTCORTE_REQUEST:
      return {
        requesting: true,
      };
    case corteConstants.GETLISTCORTE_SUCCESS:
      return {
        cortes: action.data.cortes,
        polizasCreadas: action.data.polizasCreadas,
        cortes_all: action.data.cortes,
        requesting: false
      };
    case corteConstants.GETLISTCORTE_FAILURE:
      return {
        requesting: false
      };
    case corteConstants.GETLISTABONO_REQUEST:
      return {
        requesting: true,
      };
    case corteConstants.GETLISTABONO_SUCCESS:
      return {
        cortes: action.data.cortes,
        polizasCreadas: action.data.polizasCreadas,
        cortes_all: action.data.cortes,
        requesting: false
      };
    case corteConstants.GETLISTABONO_FAILURE:
      return {
        requesting: false
      };
    case corteConstants.CREATEPOLIZA_REQUEST:
      return {
        ...state,
        creating: true,
        requesting: false
      }
    case corteConstants.CREATEPOLIZA_SUCCESS:
      return {

        created: true
      };
    case corteConstants.CREATEPOLIZA_FAILURE:
      return {
        error: action.response.message,
        created: true
      };

    case corteConstants.CREATEPOLIZAFACTURA_REQUEST:
      return {
        ...state,
        creating: true,
        requesting: false
      }
    case corteConstants.CREATEPOLIZAFACTURA_SUCCESS:
      return {

        created: true
      };
    case corteConstants.CREATEPOLIZAFACTURA_FAILURE:
      return {
        error: action.response.message,
        created: true
      };
    case corteConstants.FILTER_FACTURASCORTES:
      function filtrosfactura(currentElement) {
        let filtrosCorrectos = 0;
        let numfiltro = 0;
        for (var filtro in action.filtro) {
          if (filtro == "facturas") {
            filtrosCorrectos += (currentElement.folio_serie.toLowerCase().indexOf(action.filtro.facturas.toLowerCase()) >= 0) ? 1 : 0
          }
          numfiltro++;
        }

        if (filtrosCorrectos == numfiltro && numfiltro != 0) {
          return currentElement
        }
        if (numfiltro == 0) {
          return currentElement
        }

      }
      return {
        ...state,
        facturas: state.facturas_all.filter(filtrosfactura)
      };
    case corteConstants.FILTER_FACTURASABONO:
      function filtrosfacturas(currentElement) {
        let filtrosCorrectos = 0;
        let numfiltro = 0;
        for (var filtro in action.filtro) {
          if (filtro == "fechasFacturas") {
            var fecha1 = new Date(action.filtro.fechasFacturas[0]);
            var fecha2 = new Date(action.filtro.fechasFacturas[1]);
            var fechafactura = new Date(currentElement.fecha);
            filtrosCorrectos += (fechafactura >= fecha1 && fechafactura <= fecha2) ? 1 : 0
          }
          if (filtro == "referencia") {
            filtrosCorrectos += (currentElement.referencia.match(action.filtro.referencia)) ? 1 : 0
          }
          if (filtro == "facturas") {
            filtrosCorrectos += (currentElement.folio_serie.toLowerCase().indexOf(action.filtro.facturas.toLowerCase()) >= 0) ? 1 : 0
            // filtrosCorrectos += (currentElement.folio_serie.match(action.filtro.facturas)) ? 1 : 0
          }
          if (filtro == "nombrefiltro") {
            filtrosCorrectos += (currentElement.nombre_cliente.toLowerCase().indexOf(action.filtro.nombrefiltro.toLowerCase()) >= 0) ? 1 : 0
          }
          if (filtro == "total") {
            filtrosCorrectos += (currentElement.total.match(action.filtro.total)) ? 1 : 0
          }
          numfiltro++;
        }
        if (filtrosCorrectos == numfiltro && numfiltro != 0) {
          return currentElement
        }
        if (numfiltro == 0) {
          return currentElement
        }

      }
      return {
        ...state,
        facturas: state.facturas_all.filter(filtrosfacturas)
        /*facturas: state.facturas_all.filter(factura => {
          return action.filtro.fechafactura !== '' && action.filtro.nombre !== '' ?
            (factura.fecha === action.filtro.fechafactura && factura.nombre_cliente.toLowerCase().indexOf(action.filtro.nombre.toLowerCase()) >= 0) :
            action.filtro.fechafactura !== '' ?
              (factura.fecha === action.filtro.fechafactura) :
              factura.nombre_cliente.toLowerCase().indexOf(action.filtro.nombre.toLowerCase()) >= 0
        })*/
      };
    case corteConstants.FILTER_FACTURAS_CLEAR:
      return {
        ...state,
        facturas: state.facturas_all
      };
    case corteConstants.FILTER_CORTES:

      function filtroscortes(currentElement) {
        let filtrosCorrectos = 0;
        let numfiltro = 0;
        for (var filtro in action.filtro) {
          if (filtro == "fechascorte") {
            var fecha1 = new Date(action.filtro.fechascorte[0]);
            var fecha2 = new Date(action.filtro.fechascorte[1]);
            var fechafactura = new Date(currentElement.fecha_creacion);
            filtrosCorrectos += (fechafactura >= fecha1 && fechafactura <= fecha2) ? 1 : 0
          }
          if (filtro == "tipoCorte") {
            filtrosCorrectos += (currentElement.tipo_corte === action.filtro.tipoCorte) ? 1 : 0
          }
          if (filtro == "referencia") {
            filtrosCorrectos += (currentElement.referencias.match(action.filtro.referencia)) ? 1 : 0
          }
          if (filtro == "empresa") {
            filtrosCorrectos += (currentElement.empresa === action.filtro.empresa) ? 1 : 0
          }
          if (filtro == "facturas") {
            //currentElement.nombre_cliente.toLowerCase().indexOf(action.filtro.nombrefiltro.toLowerCase()) >= 0
            filtrosCorrectos += (currentElement.facturas.toLowerCase().indexOf(action.filtro.facturas.toLowerCase()) >= 0) ? 1 : 0
          }
          numfiltro++;
        }
        if (filtrosCorrectos == numfiltro && numfiltro != 0) {
          return currentElement
        }
        if (numfiltro == 0) {
          return currentElement
        }

      }
      return {
        ...state,
        cortes: state.cortes_all.filter(filtroscortes)
      };
    case corteConstants.FILTER_ABONO:
      return {
        ...state,
        cortes: state.cortes_all.filter(corte => {
          return (action.filtro.fechacorte !== '' && action.filtro.referencia !== '' && action.filtro.total !== '') ?
            (corte.fecha_creacion === action.filtro.fechacorte && corte.referencias.match(action.filtro.referencia) && corte.total_corte.match(action.filtro.total)) :
            action.filtro.total !== '' && action.filtro.referencia !== '' ?
              (corte.referencias.match(action.filtro.referencia) && corte.total_corte.match(action.filtro.total)) :
              action.filtro.fechacorte !== '' && action.filtro.referencia !== '' ?
                (corte.fecha_creacion === action.filtro.fechacorte && corte.referencias.match(action.filtro.referencia)) :
                action.filtro.fechacorte !== '' && action.filtro.total !== '' ?
                  (corte.fecha_creacion === action.filtro.fechacorte && corte.total_corte.match(action.filtro.total)) :
                  action.filtro.fechacorte !== '' ? corte.fecha_creacion === action.filtro.fechacorte :
                    action.filtro.total !== '' ? corte.total_corte.match(action.filtro.total) :
                      corte.referencias.match(action.filtro.referencia)
        })
      };
    case corteConstants.FILTER_CORTES_CLEAR:
      return {
        ...state,
        cortes: state.cortes_all
      };

    default:
      return {}
  }
}
