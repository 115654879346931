import { reportConstants, reportService } from '.';
import { alertActions } from '../Alert';

export const reporteactions = {
    getReporteBanco,
    filtering
};

function getReporteBanco(idUser, fechascorte = null) {
    let fechas = fechascorte ? fechascorte[0] == "" ? null : fechascorte : null
    return dispatch => {
        dispatch(request());

        reportService.getReporteBanco(idUser, fechas)
            .then(
                response => {
                    dispatch(success(response.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() { return { type: reportConstants.GETREPORTEBANCO_REQUEST } }
    function success(data) { return { type: reportConstants.GETREPORTEBANCO_SUCCESS, data } }
    function failure(error) { return { type: reportConstants.GETREPORTEBANCO_FAILURE, error } }
}

function filtering(filtro) {
    return dispatch => {
        if (typeof (filtro) === 'undefined')
            dispatch(filterClear());
        else
            dispatch(filter(filtro));

    };

    function filter(filtro) { return { type: reportConstants.FILTER_RMOVIMIENTOS, filtro } }
    function filterClear() { return { type: reportConstants.FILTER_RMOVIMIENTOS_CLEAR } }
}




