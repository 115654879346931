import React from 'react'
import { Dropdown, Form, Table, Container, Accordion, Header, Icon, Grid, Button, Label, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { exportarCWActions } from '../../'
import { chargeCodeActions, chargeCodeOptions } from '../'
import { listasActions } from '../../../Listas'
import { CustomLoader } from '../../../_custom'

class FacturasPendientes extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      activeIndex: "",
      activeFactura: "",
      activeConcepto: "",
      listaAgentes: "",
      listaFacturas: [],
      listaAgentesFiltrada: null,
      prevAgente: "",
      prevFactura: "",
      agregarChargeCode: []
    }

    this.handleAgenteClick = this.handleAgenteClick.bind(this)
    this.handleFacturaClick = this.handleFacturaClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }

  handleChange(e, data){
    let newChargeCode = this.state.agregarChargeCode.slice()
    newChargeCode[data.id] = data.value
    this.setState({
      agregarChargeCode: newChargeCode
    })
  }
  handleAgenteClick(e, titleProps){
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    if(this.state.prevAgente === index){
      this.setState({
        listaFacturas: ""
      })
    } else{
      this.setState({
        listaFacturas: ""
      })
      this.props.getList(index)
    }

    this.setState({
      prevAgente: index,
      activeIndex: newIndex
    })

    if(this.state.listaFacturas){
      this.setState({
        listaFacturas: ""
      })
    }
    this.setState({
      listaFacturas: ""
    })
  }
  handleFacturaClick(e, titleProps){
    const { index } = titleProps
    const { activeFactura, prevFactura } = this.state
    const newFacturaIndex = activeFactura === index ? -1 : index

    this.setState({
      activeFactura: newFacturaIndex,
      prevFactura: index,
    })

    if(prevFactura === index){

    } else {
      this.props.getListaConceptos(titleProps.index);
    }
  }
  handleButtonClick(e, data){
    let agregarChargeCode = []
    this.state.agregarChargeCode.forEach((item, i) => {
      if(item){
        agregarChargeCode.push({
          id_concepto: i,
          charge_code: item
        })
      }
    });
    this.props.actualizarChargeCode(agregarChargeCode)
  }

  handleFilterChange(e, data){
    if(data.value === "TODOS"){
      this.setState({
        listaAgentesFiltrada: this.state.listaAgentes
      })
    } else{
      let agentesFiltrados = this.state.listaAgentes.filter(agente => agente.rfc === data.value)
      this.setState({
        listaAgentesFiltrada: agentesFiltrados
      })
    }
  }

  getListaAgentes = () => {
    this.setState({
      listaAgentes: this.props.agentes.agentes,
    })
  }
  getListaFacturas = () => {
    this.setState({
      listaFacturas: this.props.facturas
    })
  }
  componentDidMount(){
    this.props.getAgentes()
  }

  render(){
    let { agentes, facturas, conceptos, cargandoConceptos, requesting, cargarCode } = this.props
    let { listaAgentes, listaFacturas, activeIndex, activeFactura, listaAgentesFiltrada } = this.state
    let listaAgentesMostrar = null;
    let agenteOpciones = []
    if(listaAgentes === ""){
      if(agentes){
        if(agentes.agentes){
          this.getListaAgentes()
        }
      }
    }

    if(listaFacturas === ""){
      if(facturas){
        this.getListaFacturas()
      }
    }

    if(listaAgentes){
       agenteOpciones = listaAgentes.map(x => {
        return {
          key:x.key,
          value:x.rfc,
          text:x.rfc,
        }
      })

      agenteOpciones.push({
        key:"TODOS",
        value:"TODOS",
        text:"TODOS",
      })
    }

    if(listaAgentesFiltrada){
      listaAgentesMostrar = listaAgentesFiltrada
    } else{
      listaAgentesMostrar = listaAgentes
    }

    return(
      <Grid fluid>
        <Grid.Row fluid>
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column fluid width="6">
                  <Container fluid>
                      <Form fluid>
                        <Header as="h4">Filtros</Header>
                        <Divider />
                        <Form.Group>
                          <Form.Dropdown
                          label="Filtrar por Proveedor"
                          fluid
                          placeholder="Filtrar por proveedor..."
                          selection
                          search
                          options={agenteOpciones ? agenteOpciones : null}
                          onChange={this.handleFilterChange}
                          />
                        </Form.Group>
                      </Form>
                  </Container>
                </Grid.Column>
                <Grid.Column width="6">
                  <Container>
                    <Form
                      className={cargarCode}
                    >
                      <Header as="h4">Actualizar</Header>
                      <Divider />
                      <Form.Group>
                        <Form.Button
                          label="Actualizar Charge-Code(s)"
                          id="actualizar"
                          onClick={this.handleButtonClick}
                          color="green"
                          fluid
                        >Actualizar</Form.Button>
                      </Form.Group>
                    </Form>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Grid.Row>
        <Grid.Row>
          <Container>
            <Header as="h4">Lista de Facturas</Header>
            <Divider />
            <Accordion styled fluid>
            {
              listaAgentesMostrar
                ?
                  listaAgentesMostrar.map((x, y) => {
                    return (
                      <div>
                      <Accordion.Title
                      active={activeIndex === x.key}
                      index={x.key}
                      onClick={this.handleAgenteClick}
                      >
                      <Icon name="dropdown"/>
                        {x.rfc}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === x.key}>
                      { requesting ? <CustomLoader/> : null }
                      {
                        facturas && (!requesting)
                          ?
                              <Accordion fluid styled>
                                {facturas.map((factura, index) => {
                                  return(
                                    <div>
                                      <Accordion.Title
                                        active={activeFactura === factura.key}
                                        index={factura.key}
                                        onClick={this.handleFacturaClick}
                                        color="red"
                                        id={factura.key}
                                      >
                                        <Label circular color={factura.cc_pendientes == 0 ? "green" : "red"}>{factura.cc_pendientes}</Label>
                                        <Icon name="dropdown" />
                                        {factura.serie}{factura.folio}
                                      </Accordion.Title>

                                      { conceptos && !cargandoConceptos
                                        ?
                                          <Accordion.Content active={activeFactura === factura.key}>
                                            <Table celled>
                                              <Table.Header>
                                                <Table.Row>
                                                  <Table.HeaderCell>Clave Producto Servicio</Table.HeaderCell>
                                                  <Table.HeaderCell>Clave Unidad</Table.HeaderCell>
                                                  <Table.HeaderCell>Descripción</Table.HeaderCell>
                                                  <Table.HeaderCell>Importe</Table.HeaderCell>
                                                  <Table.HeaderCell>Charge-Code</Table.HeaderCell>
                                                </Table.Row>
                                              </Table.Header>
                                              <Table.Body>
                                                {conceptos.map(concepto => {
                                                  return(
                                                    <Table.Row>
                                                      <Table.Cell>{concepto.clave_producto_servicio}</Table.Cell>
                                                      <Table.Cell>{concepto.clave_unidad}</Table.Cell>
                                                      <Table.Cell>{concepto.descripcion}</Table.Cell>
                                                      <Table.Cell>{concepto.importe}</Table.Cell>
                                                      <Table.Cell>
                                                        <Dropdown
                                                          id={concepto.id}
                                                          value={this.state.agregarChargeCode[concepto.id] ? this.state.agregarChargeCode[concepto.id] : null}
                                                          fluid
                                                          search
                                                          selection
                                                          onChange={this.handleChange}
                                                          options={chargeCodeOptions}
                                                          placeholder="Selecciona el charge-code "/>
                                                      </Table.Cell>
                                                    </Table.Row>
                                                  )
                                                })}
                                              </Table.Body>
                                            </Table>
                                          </Accordion.Content>
                                        :
                                          null
                                      }
                                    </div>
                                  )
                                })}
                              </Accordion>

                          :
                           null
                      }
                      </Accordion.Content>
                      </div>
                    )
                  })
                :
                  <CustomLoader/>
            }
            </Accordion>
          </Container>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAgentes: () =>  dispatch(exportarCWActions.getAgentes()),
    getList: (selectedSupplier) => dispatch(listasActions.getList(selectedSupplier)),
    getListaConceptos: (selectedFactura) => dispatch(listasActions.getListaConceptos(selectedFactura)),
    actualizarChargeCode: (listaConceptos) =>  dispatch(chargeCodeActions.actualizarChargeCode(listaConceptos)),
  }
}

const mapStateToProps = (state) => {
  return {
    agentes: state.cw.agentes,
    facturas: state.listas.facturas,
    conceptos: state.listas.conceptos,
    response: state.cw.response,
    cargandoConceptos: state.listas.cargandoConceptos,
    requesting: state.listas.requesting,
    cargarCode: state.cw.cargarCode,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacturasPendientes)
