import { chargeCodeConstants, getAgente,actualizarChargeCodeUrl,listarConPenUrl } from './'
import { uriDevelop, headers } from '../../../service'

export const chargeCodeService = {
  getAgentes,
  actualizarChargeCode,
  listarConPen
}

function handleResponse(response){
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if(!response.ok){
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

function getAgentes(){
  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  return fetch(`${uriDevelop}${getAgente}`, requestOptions)
    .then(handleResponse)
}

function actualizarChargeCode(conceptoList){
  console.log(conceptoList)
  let requestBody = { conceptos: conceptoList }
  console.log(requestBody)
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestBody)
  }

  console.log(requestOptions)

  return fetch(`${uriDevelop}${actualizarChargeCodeUrl}`, requestOptions)
    .then(handleResponse)
}

function listarConPen(){
  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  return fetch(`${uriDevelop}${listarConPenUrl}`, requestOptions)
    .then(handleResponse)
}
