import React from 'react'
import { Container, Header, Icon, Grid, Table, Form, Dropdown } from 'semantic-ui-react'
import { CustomLoader } from '../../../_custom'
import { connect } from 'react-redux'
import { chargeCodeActions, chargeCodeOptions } from '../'
import { exportarCWActions } from '../../'

class ConceptosPendientes extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      agregarChargeCode: [],
      conceptList: null,
      conceptListByAgent: null,
      agentList: null,
      selectedAgent: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleAgentChange = this.handleAgentChange.bind(this)
    this.handleFilterClick = this.handleFilterClick.bind(this)
  }

  handleChange(e, data){
    let newChargeCode = this.state.agregarChargeCode.slice()
    newChargeCode[data.id] = data.value
    this.setState({ agregarChargeCode: newChargeCode })
  }

  handleAgentChange(e, data){ this.setState({selectedAgent: data.value})}

  handleFilterClick(e, data){
    const conceptListByAgent = this.state.conceptList.filter(concept => concept.rfc === this.state.selectedAgent)
    this.setState({ conceptListByAgent: conceptListByAgent})
  }

  handleClick(e, data){
    let agregarChargeCode = []
    this.state.agregarChargeCode.forEach((item, i) => {if(item) agregarChargeCode.push({id_concepto: i, charge_code: item })})
    this.props.actualizarChargeCode(agregarChargeCode)
    this.setState({
      conceptList: null,
      conceptListByAgent: null,
      agregarChargeCode: []
    })
  }

  componentDidMount(){
    this.props.listarConceptosPendientes()
    this.props.getAgentes()
  }

  saveConceptList = list => this.setState({conceptList: list})
  saveAgentList = list => this.setState({agentList: list})

  render(){
    const { conceptos, cargandoConceptos, cargandoChargeCode, agentes } = this.props
    const { conceptList, conceptListByAgent, agentList, agregarChargeCode } = this.state

    const conceptListToShow = conceptListByAgent ? conceptListByAgent : conceptList

    if(agentes && !agentList) this.saveAgentList(agentes)
    if(conceptos && !conceptList) this.saveConceptList(conceptos)
    let agenteOpciones = []
    if(agentList){
      agenteOpciones = agentList.agentes.map(x => {
        return {
          key:x.key,
          value:x.rfc,
          text:x.rfc
        }
      })
    }
    return(
      <Container fluid>
        <Header size="small" dividing>
          <Icon name="cloud upload"/>
          <Header.Content>Cargar Charge-Code a Facturas</Header.Content>
          <Header.Subheader>Aquí puedes ingresar el charge-code para cada factura.</Header.Subheader>
        </Header>
        <Grid fluid>
          <Grid.Row>
            <Grid.Column width="7">
              <Form>
                <Form.Group>
                  <Form.Dropdown
                    width={12}
                    label="Filtro"
                    fluid
                    search
                    selection
                    options={agenteOpciones ? agenteOpciones : null}
                    placeholder="Filtrar por proveedor"
                    onChange={this.handleAgentChange}
                  />
                  <Form.Button
                    onClick={this.handleFilterClick}
                    primary
                    width={4}
                    label="_"
                  >Filtrar</Form.Button>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width="7">
              <Form>
                <Form.Group>
                  <Form.Button
                    width={16}
                    label="Actualizar Charge-Code(s)"
                    positive
                    className = {agregarChargeCode.length > 0 ? "" : "disabled"}
                    fluid
                    onClick={this.handleClick}
                  >
                    Actualizar</Form.Button>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="14">
              {
                cargandoConceptos || cargandoChargeCode
                ?
                  <CustomLoader />
                :
                  <Table
                    text-align="left"
                    celled
                    size="small"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>Nº</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Proveedor</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Factura</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Descripción</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell width={1}>C. Prod Serv</Table.HeaderCell>
                        <Table.HeaderCell width={1}>C. Unidad</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Charge Code</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {/* ---------------------------------------------------------------------- */}
                      {
                        conceptListToShow
                          ?
                          conceptListToShow.map((concepto, index) => {
                              return(
                                <Table.Row key={index}>
                                  <Table.Cell>{index + 1}</Table.Cell>
                                  <Table.Cell>{concepto.rfc}</Table.Cell>
                                  <Table.Cell>{concepto.serie}{concepto.folio}</Table.Cell>
                                  <Table.Cell>{concepto.descripcion}</Table.Cell>
                                  <Table.Cell>{concepto.cantidad}</Table.Cell>
                                  <Table.Cell>{concepto.clave_producto_servicio}</Table.Cell>
                                  <Table.Cell>{concepto.clave_unidad}</Table.Cell>
                                  <Table.Cell>
                                    <Dropdown
                                      search
                                      selection
                                      id={concepto.id}
                                      value={this.state.agregarChargeCode[concepto.id] ? this.state.agregarChargeCode[concepto.id] : null }
                                      options={chargeCodeOptions}
                                      placeholder="Charge-Code"
                                      onChange={this.handleChange}/>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            })
                          :
                            null
                      }
                    </Table.Body>
                  </Table>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    getAgentes: () =>  dispatch(exportarCWActions.getAgentes()),
    listarConceptosPendientes: () => dispatch(chargeCodeActions.listarConceptosPendientes()),
    actualizarChargeCode: (listaConceptos) => dispatch(chargeCodeActions.actualizarChargeCode(listaConceptos))
  }
}

const mapStateToProps = (state) => {
  return {
    cargandoChargeCode: state.cw.cargarCode,
    cargandoConceptos: state.cw.cargandoConceptos,
    conceptos: state.cw.conceptos,
    agentes: state.listas.agentes,
    eraseList: state.cw.eraseList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConceptosPendientes)
