import { alertActions } from '../../Alert'
import { exportarCWConstants, exportarCWService } from '.'

export const exportarCWActions = {
  getAgentes,
  step1,
  step2,
  step3,
  crearCSV
}

function getAgentes(){
  return dispatch => {
    dispatch(request())
    exportarCWService.getAgentes()
      .then(
        response => {
          dispatch(success(response.data));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }
  function request(){ return {type: exportarCWConstants.GET_AGENTES_REQUEST } }
  function success(agentes){ return {type: exportarCWConstants.GET_AGENTES_SUCCESS, agentes } }
  function failure(){ return {type: exportarCWConstants.GET_AGENTES_FAILURE } }
}

function crearCSV(agentes){
  return dispatch => {
    dispatch(request())
    exportarCWService.getCSV()
      .then(
        response => {
          dispatch(success(response.data))
        },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      );
  }
  function request(){ return {type: exportarCWConstants.CREATE_CSV_REQUEST } }
  function success(facturasCSV){ return {type: exportarCWConstants.CREATE_CSV_SUCCESS, facturasCSV} }
  function failure(){ return {type: exportarCWConstants.CREATE_CSV_FAIULRE } }
}

function step1(){
  return dispatch => { dispatch(success()) }
  function success(){return {type: exportarCWConstants.CHANGE_STEP_1} }
}

function step2(){
  return dispatch => { dispatch(success()) }
  function success(){return {type: exportarCWConstants.CHANGE_STEP_2} }
}

function step3(){
  return dispatch => { dispatch(success()) }
  function success(){return {type: exportarCWConstants.CHANGE_STEP_3} }
}

function createTable(){
  return dispatch => {

  }
}
