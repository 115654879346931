import React, { Component } from 'react';
import { Button, Table, Header, Grid, Icon, Modal, Popup, Dropdown, Input } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import { connect } from 'react-redux';
import { corteActions, tipoCorte, tipoPoliza, tipoCuenta } from '..';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';
import { CustomLoader } from '../../_custom';

class ListarCorte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            idcorte: 0,
            fechaPoliza: new Date(),
            tipodeposito: "",
            cortes: [],
            fechascorte: ['', ''],
            companies: [],
            total: "",
            importe: "",
            banco: "2",
            company: '',
            tipoCorte: '',
            tipoCuenta: '',
            sucursal: '',
            referencia: '',
            facturasfiltro: ""
        }
    }
    show = (id, tipo, total, importe, sucursal) => () => this.setState({
        open: true,
        idcorte: id,
        total: total,
        importe: importe,
        sucursal: sucursal,
        tipodeposito: tipo
    })



    close = () => this.setState({
        open: false
    })

    componentDidMount() {
        this.getEmpresas();
        /*const { cortes } = this.props
        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
        this.props.getCortes(idUser);
        this.getEmpresas();
        this.setState({
            cortes: cortes
        });*/
    }

    getEmpresas() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.companies) {
            let array = [];
            for (let index = 0; index < user.companies.length; index++) {
                let company = {
                    'key': user.companies[index].id,
                    'text': user.companies[index].nombre,
                    'value': user.companies[index].nombre
                }
                array.push(company);
            }
            this.setState({
                companies: array
            });
        }
    }

    onSelectionChanged = (e, data) => {
        const { fechascorte, referencia, tipoCorte, facturasfiltro } = this.state;
        var filtro = new Object();
        this.setState({ company: data.value });
        if (data.value !== '')
            filtro.empresa = data.value;
        if (referencia !== '')
            filtro.referencia = tipoCorte;
        if (tipoCorte !== '')
            filtro.tipoCorte = tipoCorte;
        if (facturasfiltro !== '')
            filtro.facturas = facturasfiltro;
        if (fechascorte.length > 0)
            if (fechascorte[0] != "")
                filtro.fechascorte = fechascorte;
        // this.props.filteringcorte(filtro)

    }

    onSelectionChangedTipo = (e, data) => {
        const { fechascorte, referencia, company, facturasfiltro } = this.state;
        var filtro = new Object();
        this.setState({ tipoCorte: data.value });
        if (data.value !== '')
            filtro.tipoCorte = data.value;
        if (company !== '')
            filtro.empresa = company;
        if (referencia !== '')
            filtro.referencia = tipoCorte;
        if (facturasfiltro !== '')
            filtro.facturas = facturasfiltro;
        if (fechascorte.length > 0)
            if (fechascorte[0] != "")
                filtro.fechascorte = fechascorte;
        this.props.filteringcorte(filtro)

    }

    handleInputChangeReferencia = (e) => {
        const { fechascorte, company, tipoCorte, facturasfiltro } = this.state;
        var filtro = new Object();
        let amount = e.target.value;
        this.setState({ referencia: amount })
        if (amount !== '')
            filtro.referencia = amount;
        if (company !== '')
            filtro.empresa = company;
        if (tipoCorte !== '')
            filtro.tipoCorte = tipoCorte;
        if (facturasfiltro !== '')
            filtro.facturas = facturasfiltro;
        if (fechascorte.length > 0)
            if (fechascorte[0] != "")
                filtro.fechascorte = fechascorte;
         this.props.filteringcorte(filtro)
    }

    handleInputChangefacturas = (e) => {
        const { fechascorte, company, tipoCorte, referencia } = this.state;
        var filtro = new Object();
        let amount = e.target.value;
        this.setState({ facturasfiltro: amount })
        if (amount !== '')
            filtro.facturas = amount;
        if (company !== '')
            filtro.empresa = company;
        if (tipoCorte !== '')
            filtro.tipoCorte = tipoCorte;
        if (referencia !== '')
            filtro.referencia = tipoCorte;
        if (fechascorte.length > 0)
            if (fechascorte[0] != "")
                filtro.fechascorte = fechascorte;
        this.props.filteringcorte(filtro)
    }

    onDateChangedcorte = date => {
        const { company, referencia, tipoCorte, facturasfiltro } = this.state
        let fechasformateadas = [];
        var filtro = new Object();
        if (date !== null) {
            let fechaform = [];
            date.forEach(dat => {
                let mes = (dat.getMonth()) + 1;
                let dia = dat.getDate()
                fechaform = dat.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
                fechasformateadas.push(fechaform);
            });
            this.setState({
                fechascorte: fechasformateadas
            })
            filtro.fechascorte = fechasformateadas;
            if (company !== '')
                filtro.empresa = company;
            if (tipoCorte !== '')
                filtro.tipoCorte = tipoCorte;
            if (referencia !== '')
                filtro.referencia = tipoCorte;
            if (facturasfiltro !== '')
                filtro.facturas = facturasfiltro;
            //  this.props.filteringcorte(filtro)
        }
        else {
            this.setState({
                fechascorte: ['', '']
            })
            if (company !== '')
                filtro.empresa = company;
            if (tipoCorte !== '')
                filtro.tipoCorte = tipoCorte;
            if (referencia !== '')
                filtro.referencia = tipoCorte;
            if (facturasfiltro !== '')
                filtro.facturas = facturasfiltro;
            //  this.props.filteringcorte(filtro)
        }
    }

    onDateChangedpoliza = date => {
        this.setState({
            fechaPoliza: date,
        })
    }

    onSelectionChangedCuenta = (e, data) => {
        if (data.value !== '') {
            this.setState({ banco: data.value, tipoCuenta: data.value });
        }
        else {
            this.setState({ tipoCuenta: data.value });
        }
    }

    clearstate() {
        this.setState({
            open: false,
            idcorte: 0,
            fechaPoliza: new Date(),
            idempresa: 0,
            sucursal: '',
            company: '',
            fechacorteInicial: "",
        });
    }
    onfilter = (e) => {
        e.preventDefault()
        const { fechascorte, company } = this.state
        let idUser = JSON.parse(localStorage.getItem("user")).user.id;
        this.props.getCortes(idUser, fechascorte, company);
        this.setState({
            cortes: this.props.cortes
        });
    }
    onSubmit = () => {
        const { idcorte, tipodeposito, fechaPoliza, total, importe, banco, sucursal } = this.state
        let metodo = tipodeposito == 1 ? 'efectivo' : tipodeposito == 2 ? 'tarjeta' : tipodeposito == 3 ? 'Amex' : ''
        let mes = (fechaPoliza.getMonth()) + 1;
        let dia = fechaPoliza.getDate()
        let fechaform = fechaPoliza.getFullYear() + '/' + (mes < 10 ? '0' + mes : mes) + '/' + (dia < 10 ? '0' + dia : dia)
        let nombre = 'Poliza ' + metodo + ' ' + sucursal + ' ' + fechaform;
        let extension = '.xls';
        let datospoliza = [
            {
                tipo_pago: tipodeposito,
                total: total,
                importe: importe,
                banco: banco,
                concepto: 'DEPOSITO DEL DIA'
            }
        ]
        let data = {
            idrelacionado: idcorte,
            fechapoliza: fechaPoliza,
            datospoliza,
            nombre: nombre,
            extension, extension
        }
        this.clearstate();
        this.props.crearPoliza(data);

    }

    render() {
        const { requesting, creating, cortes, polizasCredas } = this.props;
        const { open, fechaPoliza, companies, facturasfiltro, referencia, fechascorte } = this.state;
        let tipo_user = JSON.parse(localStorage.getItem("user")).user.id_tipo;
        if (requesting) {
            return <CustomLoader active inline='centered'
                text='Cargando cortes' />
        }
        else if (creating) {
            return <CustomLoader active inline='centered'
                text='Creando poliza' />
        }
        return (<Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='medium' dividing>
                        <Icon name='money bill alternate' />
                        <Header.Content>
                            {`Cortes`}
                            <Header.Subheader>{ }</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
                <Grid.Column width={3}>
                    <DateRangePicker
                        onChange={this.onDateChangedcorte}
                        value={fechascorte}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <Dropdown
                        placeholder='Selecciona la sucursal'
                        clearable
                        options={companies}
                        selection
                        onChange={this.onSelectionChanged}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Button onClick={this.onfilter.bind(this)} color='blue' size='large'>
                        Obtener datos
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header size='tiny' dividing>
                        <Icon name='search' />
                        <Header.Content>
                            {`Filtro de datos mostrados`}
                            <Header.Subheader>{ }</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
                <Grid.Column width={3}>
                    <Dropdown
                        placeholder='Selecciona el tipo'
                        clearable
                        options={tipoCorte}
                        selection
                        onChange={this.onSelectionChangedTipo}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <Input icon='eye' type='text'
                        placeholder='Referencia...'
                        onChange={(e) => this.handleInputChangeReferencia(e)}
                        value={referencia}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
                <Grid.Column width={3}>
                    <Input icon='eye' type='text'
                        placeholder='facturas...'
                        onChange={(e) => this.handleInputChangefacturas(e)}
                        value={facturasfiltro}
                        pattern="(^\d+\.?\d+$)|(^\d+%$)" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row colums={2}>
                <Grid.Column width={11}>
                    <Table color='teal' selectable  >
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Tipo Registro</Table.HeaderCell>
                                <Table.HeaderCell>Sucursal</Table.HeaderCell>
                                <Table.HeaderCell>Fecha</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                                <Table.HeaderCell>Efectivo</Table.HeaderCell>
                                <Table.HeaderCell>Tarjeta</Table.HeaderCell>
                                <Table.HeaderCell>AMEX</Table.HeaderCell>
                                <Table.HeaderCell>Cheque</Table.HeaderCell>
                                <Table.HeaderCell>facturas</Table.HeaderCell>
                                <Table.HeaderCell>referencias</Table.HeaderCell>
                                <Table.HeaderCell>Tipo pago</Table.HeaderCell>
                                <Table.HeaderCell>Metodo Pago Factura</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                cortes.map((c, i) => {
                                    const totalCorte = c.total_corte;
                                    const totalEfectivo = c.total_efectivo;
                                    const totalTarjeta = c.total_tarjeta;
                                    const totalAmex = c.total_amex;
                                    const totalcheque = c.total_cheque;
                                    const disableefectivo = polizasCredas.filter(boton => boton.tipo == 1 && boton.id_corte == c.id).length > 0 ? true : false;
                                    const disabletarjeta = polizasCredas.filter(boton => boton.tipo == 2 && boton.id_corte == c.id).length > 0 ? true : false;
                                    const disableamex = polizasCredas.filter(boton => boton.tipo == 3 && boton.id_corte == c.id).length > 0 ? true : false;
                                    const disablecheque = polizasCredas.filter(boton => boton.tipo == 4 && boton.id_corte == c.id).length > 0 ? true : false;
                                    const disableCobranza = polizasCredas.filter(boton => boton.tipo == 5 && boton.id_corte == c.id).length > 0 ? true : false;
                                    const conentCobranza = disableCobranza ? "Poliza creada" : "Poliza";
                                    const contentEfectivo = disableefectivo ? "Poliza de efectivo creada" : "Poliza efectivo";
                                    const contentTarjeta = disabletarjeta ? "Poliza de tarjeta creada" : "Poliza tarjeta";
                                    const contentAMEX = disableamex ? "Poliza de AMEX creada" : "Poliza AMEX";
                                    const contentCheque = disableamex ? "Poliza de Cheque creada" : "Poliza Cheque";
                                    const content = disableamex ? "Poliza de Cheque creada" : "Poliza Cheque";
                                    if (tipo_user == 1) {
                                        if (c.tipo_corte == 'Corte') {
                                            return (<Table.Row key={c.id} >
                                                <Table.Cell>
                                                    <Popup content={contentEfectivo} inverted trigger={
                                                        <Button size='tiny' onClick={this.show(c.id, 1, totalCorte, totalEfectivo, c.empresa)} disabled={totalEfectivo == 0 ? true : false} basic={!disableefectivo} color='black' icon color='black'>
                                                            <Icon name='file excel outline' />
                                                        </Button>} />
                                                    <Popup content={contentTarjeta} inverted trigger={
                                                        <Button size='tiny' onClick={this.show(c.id, 2, totalCorte, totalTarjeta, c.empresa)} disabled={totalTarjeta == 0 ? true : false} basic={!disabletarjeta} color='black' icon color='red'>
                                                            <Icon name='file excel outline' />
                                                        </Button>} />
                                                    <Popup content={contentAMEX} inverted trigger={
                                                        <Button size='tiny' onClick={this.show(c.id, 3, totalCorte, totalAmex, c.empresa)} disabled={totalAmex == 0 ? true : false} basic={!disableamex} color='black' icon color='green'>
                                                            <Icon name='file excel outline' />
                                                        </Button>} />
                                                    <Popup content={contentCheque} inverted trigger={
                                                        <Button size='tiny' onClick={this.show(c.id, 4, totalCorte, totalcheque, c.empresa)} disabled={totalcheque == 0 ? true : false} basic={!disablecheque} color='black' icon color='blue'>
                                                            <Icon name='file excel outline' />
                                                        </Button>} />
                                                </Table.Cell>
                                                <Table.Cell >{c.tipo_corte}</Table.Cell>
                                                <Table.Cell >{c.empresa}</Table.Cell>
                                                <Table.Cell >{c.period1 + "-" + c.period2}</Table.Cell>
                                                <Table.Cell><Currency quantity={totalCorte} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalEfectivo} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalTarjeta} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalAmex} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalcheque} /></Table.Cell>
                                                <Table.Cell>{c.facturas}</Table.Cell>
                                                <Table.Cell>{c.referencias}</Table.Cell>
                                                <Table.Cell>{c.metodo_pago}</Table.Cell>
                                                <Table.Cell>{c.tipoPago}</Table.Cell>
                                            </Table.Row>
                                            )
                                        }
                                        else {
                                            return (<Table.Row key={c.id} >
                                                <Table.Cell>
                                                    <Popup content={conentCobranza} inverted trigger={
                                                        <Button size='tiny' onClick={this.show(c.id, 5, totalCorte, totalCorte, c.empresa)} basic={!disableCobranza} color='black' icon color='yellow'>
                                                            <Icon name='file excel outline' />
                                                        </Button>} />
                                                </Table.Cell>
                                                <Table.Cell >{c.tipo_corte}</Table.Cell>
                                                <Table.Cell >{c.empresa}</Table.Cell>
                                                <Table.Cell >{c.period1 + "-" + c.period2}</Table.Cell>
                                                <Table.Cell><Currency quantity={totalCorte} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalEfectivo} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalTarjeta} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalAmex} /></Table.Cell>
                                                <Table.Cell><Currency quantity={totalcheque} /></Table.Cell>
                                                <Table.Cell>{c.facturas}</Table.Cell>
                                                <Table.Cell>{c.referencias}</Table.Cell>
                                                <Table.Cell>{c.tipoPago}</Table.Cell>
                                                <Table.Cell>{c.metodo_pago}</Table.Cell>
                                            </Table.Row>
                                            )
                                        }
                                    }
                                    if (tipo_user == 2) {
                                        return (<Table.Row key={c.id} >
                                            <Table.Cell >{c.empresa}</Table.Cell>
                                            <Table.Cell >{c.fecha_creacion}</Table.Cell>
                                            <Table.Cell><Currency quantity={totalCorte} /></Table.Cell>
                                            <Table.Cell><Currency quantity={totalEfectivo} /></Table.Cell>
                                            <Table.Cell><Currency quantity={totalTarjeta} /></Table.Cell>
                                            <Table.Cell><Currency quantity={totalAmex} /></Table.Cell>
                                        </Table.Row>
                                        )
                                    }
                                }
                                )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
            <Modal size='tiny' open={open}>
                <Modal.Header >
                    Selecciona la Fecha con la que quieres crear la poliza
                </Modal.Header>
                <Modal.Actions>
                    <Table size={'small'}>
                        <Table.Row>
                            <Table.Cell >
                                <DatePicker
                                    value={fechaPoliza}
                                    onChange={this.onDateChangedpoliza}
                                    clearIcon={null}
                                    locale={'es-419'}
                                    format={'yyyy-MM-dd'}
                                />
                            </Table.Cell >
                            <Table.Cell >
                                <Dropdown
                                    placeholder='Seleccione tipo de cuenta'
                                    clearable
                                    options={tipoCuenta}
                                    //text='Cuenta Eje'
                                    defaultSelectedLabel
                                    defaultValue={'2'}
                                    selection
                                    onChange={this.onSelectionChangedCuenta}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                    <Button size='mini' negative onClick={this.close}>Cancelar</Button>
                    <Button size='mini' positive onClick={this.onSubmit}>Confirmar</Button>
                </Modal.Actions>
            </Modal>
        </Grid >)
    }
}
const mapStateToProps = (state) => {
    return {
        cortes: state.corte.cortes ? state.corte.cortes : [],
        polizasCredas: state.corte.polizasCreadas ? state.corte.polizasCreadas : [],
        requesting: state.corte.requesting,
        creating: state.corte.creating,
        created: state.corte.created
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCortes: (idUser,fechascorte,company) => dispatch(corteActions.getCorteAll(idUser,fechascorte,company)),
        crearPoliza: (data) => dispatch(corteActions.crearPolizaCorte(data)),
        filteringcorte: (filtro) => dispatch(corteActions.filteringCorte(filtro))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListarCorte)