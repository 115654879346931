export const supplierConstants = {
    REGISTER_REQUEST: 'SUPPLIERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'SUPPLIERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'SUPPLIERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'SUPPLIERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'SUPPLIERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'SUPPLIERS_LOGIN_FAILURE',

    LOGOUT: 'SUPPLIERS_LOGOUT',

    GETALL_REQUEST: 'SUPPLIERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'SUPPLIERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'SUPPLIERS_GETALL_FAILURE',

    DELETE_REQUEST: 'SUPPLIERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'SUPPLIERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'SUPPLIERS_DELETE_FAILURE'
};

export const supplierLogin = '/proveedor/login'