import {
    getReporte
} from '.';

import { uriDevelop, headers } from '../../service'

export const reportService = {
    getReporteBanco
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getReporteBanco(idUser,fechas) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    let apirequest = `${uriDevelop}${getReporte}?idUsuario=${idUser}`;
    if (fechas != null)
        apirequest += `&fecha1=${fechas[0]}&fecha2=${fechas[1]}`;
        
    return fetch(apirequest, requestOptions)
    .then(handleResponse)
            .then(response => {
                return response;
            });
}
