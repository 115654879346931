import {
  getAgente,
  get_CSV
}  from './'

import {
  uriDevelop,
  headers
} from '../../../service'

export const exportarCWService = {
  getAgentes,
  getCSV
}

function handleResponse(response){
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if(!response.ok){
      const error = (data && data.message) || response.statusText
      return Promise.reject(error);
    }
    return data;
  });
}

function getAgentes(){
  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  return fetch(`${uriDevelop}${getAgente}`, requestOptions)
    .then(handleResponse);
}


function getCSV(){
  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  return fetch(`${uriDevelop}${get_CSV}`, requestOptions)
    .then(handleResponse);
}
